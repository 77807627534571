import React, {forwardRef, Fragment, useRef, useState} from 'react';
import {Field, Form} from "react-final-form";
import {Autocomplete, KeyboardDatePicker, Select, TextField} from "mui-rff";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import validate from "validate.js";
import axios from "axios";
import {API_URL} from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import Dropzone from "../Dropzone/Dropzone";
import jsonToFormData from "json-form-data";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Message from "../Message/Message";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from 'final-form-arrays'
import {isEmpty} from "lodash";
import DialogContentText from "@material-ui/core/DialogContentText";
import MDialog from "@material-ui/core/Dialog"
import Dialog from "../Dialog/Dialog";
import {useSelector} from "react-redux";
import {checkIfArrayIsUnique, toInputUppercase} from "../../helpers/helpers";
import createDecorator from "final-form-focus";
import SignatureCanvas from 'react-signature-canvas'
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import {withSnackbar} from "notistack";
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    subtitle: {
        marginTop: theme.spacing(2)
    },
    flexCenter: {
        display: 'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'row'
    },
    arrayFieldWrapper: {
        backgroundColor: '#fafafa',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#eeeeee',
        padding: 16,
        borderRadius: 2,
        marginTop: 16
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    },
    thumbInner: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 0,
        overflow: 'hidden',
        '&:hover $imageRemove': {
            opacity: 1
        }
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%'
    },
}));

validate.validators.customDate = function(value, options, key, attributes) {
    return moment.isMoment(value) && value.isValid() ? null : "Debe de ingresar una fecha válida";
};

validate.validators.unique = function(value, options, key, attributes) {
    return checkIfArrayIsUnique(value) ? null : "No debe contener valores repetidos";
};

validate.validators.array = (arrayItems, itemConstraints) => {

    const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
        const error = validate(item, itemConstraints, {fullMessages: false})
        if (error) errors.push({...error, index})
        return errors
    }, []);

    return isEmpty(arrayItemErrors) ? null : arrayItemErrors
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const WorkersCreateForm = ({ initialValues, states, companies, sexes, civilStatuses, educations, fees, history, onSuccess, onError, onLoadingChange, submitting, enqueueSnackbar }) => {

    const authState = useSelector((state) => state.authReducer);

    const focusOnErrors = createDecorator();

    const defaultEmergencyContact = { name: '', phone: '', relationship: '' };
    const defaultFee = { id: '' };

    const [showEmployment, setShowEmployment] = useState(false);

    const [isSearching, setIsSearching] = useState(false);
    const [foundWorkerId, setFoundWorkerId] = useState('');

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isSignatureDialogOpen, setIsSignatureDialogOpen] = useState(false);

    const classes = useStyles();

    const signatureRef = useRef(null);

    // Reglas de validación de formulario
    const constraints = (values) => ({
        worker_number: (value, attributes, attributeName, options, constraints) => {

            if(value) {
                return {
                    numericality: {
                        onlyInteger: true,
                        message: 'Solo debe de contener números'
                    }
                }
            } else {
                return null
            }
        },
        curp: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            },
            length: {
                is: 18,
                message: 'Debe de contener 18 caracteres'
            }
        },
        rfc: (value, attributes, attributeName, options, constraints) => {

            if(value){
                return {
                    length: {
                        is: 13,
                        message: 'Debe de contener 13 caracteres'
                    }
                }
            } else {
                return null
            }
        },
        first_name: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        fathers_last_name: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        birth_at: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    customDate: {
                        message: "Debe de ingresar una fecha válida"
                    }
                }
            } else {
                return null
            }
        },
        state_id: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    inclusion: {
                        within: states.map(state => state.id),
                        message: 'Debe seleccionar un valor del listado'
                    }
                }
            } else {
                return null
            }
        },
        sex: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    inclusion: {
                        within: sexes.map(sex => sex.value),
                        message: 'Debe seleccionar un valor del listado'
                    }
                }
            } else {
                return null
            }
        },
        civil_status: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    inclusion: {
                        within: civilStatuses.map(civilStatus => civilStatus.value),
                        message: 'Debe seleccionar un valor del listado'
                    }
                }
            } else {
                return null
            }
        },
        education_level: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    inclusion: {
                        within: educations.map(education => education.value),
                        message: 'Debe seleccionar un valor del listado'
                    }
                }
            } else {
                return null
            }
        },
        imss: (value, attributes, attributeName, options, constraints) => {

            if(value) {
                return {
                    numericality: {
                        onlyInteger: true,
                        message: 'Solo debe de contener números'
                    },
                    length: {
                        is: 11,
                        message: 'Debe de contener 11 caracteres'
                    }
                }
            } else {
                return null;
            }
        },
        home_phone: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    numericality: {
                        onlyInteger: true,
                        notValid: 'El número de teléfono solo debe contener números.',
                    },
                    length: {
                        is: 10,
                        notValid: 'El número de teléfono debe contener 10 dígitos.',
                        wrongLength: 'El número de teléfono debe contener 10 dígitos.',
                    }
                }
            } else {
                return null;
            }
        },
        mobile_phone: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    numericality: {
                        onlyInteger: true,
                        notValid: 'El número de teléfono solo debe contener números.',
                    },
                    length: {
                        is: 10,
                        notValid: 'El número de teléfono debe contener 10 dígitos.',
                        wrongLength: 'El número de teléfono debe contener 10 dígitos.',
                    }
                }
            } else {
                return null;
            }
        },
        email: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    email: {
                        message: 'Debe de ingresar un correo válido.',
                    }
                }
            } else {
                return null;
            }
        },
        sign_up_at: {
            customDate: {
                message: "Debe de ingresar una fecha válida"
            }
        },
        emergency_contacts: {
            array: {
                name: {
                    presence: {
                        allowEmpty: false,
                        message: 'Debe de llenar este campo'
                    },
                },
                phone: {
                    presence: {
                        allowEmpty: false,
                        message: 'Debe de llenar este campo'
                    },
                    numericality: {
                        onlyInteger: true,
                        notValid: 'El número de teléfono solo debe contener números.',
                    },
                    length: {
                        is: 10,
                        notValid: 'El número de teléfono debe contener 10 dígitos.',
                        wrongLength: 'El número de teléfono debe contener 10 dígitos.',
                    }
                },
                relationship: {
                    presence: {
                        allowEmpty: false,
                        message: 'Debe de llenar este campo'
                    }
                },
            }
        },
        company_id: (value, attributes, attributeName, options, constraints) => {
            if(showEmployment) {
                return {
                    presence: {
                        allowEmpty: false,
                        message: 'Debe de llenar este campo'
                    },
                    inclusion: {
                        within: companies.map(company => company.id),
                        message: 'Debe seleccionar un valor del listado'
                    }
                }
            } else {
                return null;
            }
        },
        job_position: (value, attributes, attributeName, options, constraints) => {
            if(showEmployment) {
                return {
                    presence: {
                        allowEmpty: false,
                        message: 'Debe de llenar este campo'
                    }
                }
            } else {
                return null;
            }
        },
        location: (value, attributes, attributeName, options, constraints) => {
            if(showEmployment) {
                return {
                    presence: {
                        allowEmpty: false,
                        message: 'Debe de llenar este campo'
                    }
                }
            } else {
                return null;
            }
        },
        shift: (value, attributes, attributeName, options, constraints) => {
            if(showEmployment) {
                return {
                    presence: {
                        allowEmpty: false,
                        message: 'Debe de llenar este campo'
                    }
                }
            } else {
                return null;
            }
        },
        start_at: (value, attributes, attributeName, options, constraints) => {
            if(showEmployment) {
                return {
                    presence: {
                        allowEmpty: false,
                        message: 'Debe de llenar este campo'
                    },
                    customDate: {
                        message: "Debe de ingresar una fecha válida"
                    }
                }
            } else {
                return null;
            }
        },
    });

    // Función que se ejecuta al enviar formulario
    async function onSubmit(values, form) {

        // Mostrar indicador de carga
        onLoadingChange(true);

        try {

            let options = {
                initialFormData: new FormData(),
                showLeafArrayIndexes: true,
                includeNullValues: true
            };

            let data = {...values};

            if(data.birth_at)
                data.birth_at = data.birth_at.format('YYYY-MM-DD');

            if(data.sign_up_at)
                data.sign_up_at = data.sign_up_at.format('YYYY-MM-DD');

            data.should_save_employment = showEmployment;

            if(!showEmployment){
                data.company_id = null;
                data.employee_number = null;
                data.start_at = null;
                data.job_position = null;
                data.shift = null;
                data.location = null;
            } else {
                data.start_at = data.start_at.format('YYYY-MM-DD');
            }

            const formData = jsonToFormData(data, options);

            // Enviar datos a API
            await axios.post(
                `${API_URL}/workers`,
                formData
            );

            setTimeout(form.restart);
            setShowEmployment(false);

            // Enviar mensaje de éxito a componente padre
            onSuccess('Trabajador creado.');

            // Esconder indicador de carga
            onLoadingChange(false);

        } catch (error) {

            console.log(error);

            // Esconder indicador de carga
            onLoadingChange(false);

            // Dependiendo del error, mostrar mensajes
            switch(error.response.status){
                case 400:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                case 422:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                default:
                    // Mostrar mensaje
                    onError("Ocurrió un error desconocido.");
                    break;
            }

        }

    }

    // Función que valida el formulario
    async function validateForm(values) {

        // Validar campos
        let valid = validate({ ...values, emergency_contacts_array: values.emergency_contacts, fees_array: values.fees }, constraints(values), {fullMessages: false});

        // Si no hay error
        if(!valid) {
            return;
        } else {

            return formatValidation(valid);
        }
    }

    const formatValidation = (valid) => {

        // Modificar objeto para mostrar errores en formulario
        Object.keys(valid).forEach((key, index) => {
            if(key === 'emergency_contacts' || key === 'fees' ){
                let newValues = [];

                for(let i = 0; i < valid[key].length; i++){
                    newValues[valid[key][i].index] = formatValidation(valid[key][i]);
                }

                valid[key] = newValues;
            } else if(key !== 'index'){
                valid[key] = valid[key][0];
            }
        });

        return valid;

    };

    // Función que manipula objeto para mostrar errores
    const handleServerError = (error) => {

        Object.keys(error).forEach((key, index) => {
            error[key] = error[key][0];
        });

        return error;
    };

    const handleCurpBlur = async (curp) => {

        if(curp && curp.length === 18) {

            setIsSearching(true);

            try {

                const response = await axios.get(
                    `${API_URL}/workers/worker/get`,
                    {
                        params: {
                            curp,
                        }
                    }
                );

                let workerData = response.data;

                if(workerData.id) {
                    setFoundWorkerId(workerData.id);
                    setIsDialogOpen(true);
                }

            } catch (error) {

                onError('Ocurrió un error cargando el registro.');

            }

            // Esconder indicador de carga
            setIsSearching(false);

        }
    };

    const handleDialogAccept = () => {

        setIsDialogOpen(false);

        history.push(`/workers/${foundWorkerId}/edit`);
    };

    const handleSignatureDialogAccept = (form) => {

        if(!signatureRef.current.isEmpty()){
            setIsSignatureDialogOpen(false);

            const signatureBlob = dataURLtoBlob(signatureRef.current.getTrimmedCanvas().toDataURL('image/png'));

            form.mutators.setSignature(signatureBlob);

        } else {
            enqueueSnackbar('Debe de dibujar algo para poder guardar.');
        }

    };

    const renderEmergencyContacts = (fields, push, values, form) => {
        return fields.map((name, index) => {

            return (
                <div key={name} className={classes.arrayFieldWrapper}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="subtitle2">Contacto de Emergencia #{index + 1}</Typography>
                        <IconButton aria-label="delete" color="secondary" onClick={ () => {
                            fields.remove(index);
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Grid container spacing={1}>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Nombre Completo*" name={`${name}.name`} inputProps={{onInput: toInputUppercase}}/>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Teléfono*" name={`${name}.phone`} inputProps={{onInput: toInputUppercase}}/>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Parentesco*" name={`${name}.relationship`} inputProps={{onInput: toInputUppercase}}/>
                        </Grid>
                    </Grid>
                </div>
            )
        })
    };

    // Opciones de select
    const statesOptions = states.map(state => (
        <MenuItem key={state.id} value={state.id}>{ state.name }</MenuItem>
    ));

    // Opciones de select
    const sexOptions = sexes.map(sex => (
        <MenuItem key={sex.value} value={sex.value}>{ sex.label }</MenuItem>
    ));

    // Opciones de select
    const civilStatusOptions = civilStatuses.map(civilStatus => (
        <MenuItem key={civilStatus.value} value={civilStatus.value}>{ civilStatus.label }</MenuItem>
    ));

    // Opciones de select
    const educationOptions = educations.map(education => (
        <MenuItem key={education.value} value={education.value}>{ education.label }</MenuItem>
    ));

    // Opciones de select
    const companiesOptions = companies.map(company => (
        <MenuItem key={company.id} value={company.id}>{ company.name }</MenuItem>
    ));

    return (
        <>
            <Form
                onSubmit={onSubmit}
                mutators={{
                    ...arrayMutators,
                    setSignature: (args, state, utils) => {
                        utils.changeValue(state, 'signature', () => args[0])
                    },
                    setPrice: (args, state, utils) => {
                        const defaultPrice = fees.find(fee => fee.id === args[0]).price;
                        utils.changeValue(state, `fees[${args[1]}].price`, () => defaultPrice)
                    },
                    resetItemValues: (args, state, utils) => {
                        utils.changeValue(state, `fees[${args[0]}].price`, () => 0);
                    }
                }}
                initialValues={initialValues}
                decorators={[ focusOnErrors ]}
                validate={validateForm}
                render={({
                     handleSubmit,
                     values,
                     submitError,
                     form,
                     form: {
                         mutators: { push, pop, setSignature }
                     }
                 }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Typography variant="subtitle2" className={ classes.subtitle }>Información General</Typography>
                        <TextField
                            label="CURP*"
                            name="curp"
                            fullWidth
                            onBlur={() => handleCurpBlur(values.curp)}
                            InputProps={{
                                endAdornment: (
                                    <Fragment>
                                        {isSearching ? <CircularProgress color="inherit" size={20} /> : null}
                                    </Fragment>
                                ),
                            }}
                            inputProps={{onInput: toInputUppercase}}
                        />
                        <Grid container spacing={3}>
                            <Grid item sm={4} xs={12}>
                                <TextField label="Número de Trabajador*" name="worker_number" inputProps={{onInput: toInputUppercase}} />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <TextField label="RFC*" name="rfc" inputProps={{onInput: toInputUppercase}} />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <KeyboardDatePicker label="Fecha de Alta*" name="sign_up_at" disableFuture={true} format={'DD-MM-YYYY'} disableToolbar={false}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={3} xs={12}>
                                <TextField label="Nombre(s)*" name="first_name" inputProps={{onInput: toInputUppercase}} />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField label="Apellido Paterno*" name="fathers_last_name" inputProps={{onInput: toInputUppercase}} />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField label="Apellido Materno" name="mothers_last_name" inputProps={{onInput: toInputUppercase}} />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <Select name="sex" label="Sexo*" formControlProps={{ margin: 'normal' }}>
                                    { sexOptions }
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={4} xs={12}>
                                <KeyboardDatePicker label="Fecha de Nacimiento*" name="birth_at" disableFuture={true} format={'DD-MM-YYYY'} disableToolbar={false}/>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <Select name="state_id" label="Estado de Nacimiento*" formControlProps={{ margin: 'normal' }}>
                                    { statesOptions }
                                </Select>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <Select name="civil_status" label="Estado Civil*" formControlProps={{ margin: 'normal' }}>
                                    { civilStatusOptions }
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={12}>
                                <Select name="education_level" label="Último Grado De Estudios*" formControlProps={{ margin: 'normal' }}>
                                    { educationOptions }
                                </Select>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField label="Observaciones" name="education_observations" inputProps={{onInput: toInputUppercase}} />
                            </Grid>
                        </Grid>
                        <Typography variant="subtitle2" className={ classes.subtitle }>Fotografía de Perfil</Typography>
                        <Field name="image" subscription={{submitSucceeded: true}}>
                            {props => (
                                <Dropzone
                                    {...props.input}
                                    {...props.meta}
                                />
                            )}
                        </Field>
                        <Typography variant="subtitle2" className={ classes.subtitle }>Firma</Typography>
                        <Message
                            message="De click en el botón para capturar la firma."
                            hasAction={true}
                            actionLabel="Capturar"
                            onActionPress={() => setIsSignatureDialogOpen(true)}
                            style={{marginTop: 20}}
                        />
                        <Field name="signature" subscription={{submitSucceeded: true}}>
                            {props => (
                                values.signature ?
                                    <div className={ classes.thumb }>
                                        <div className={ classes.thumbInner }>
                                            <img
                                                {...props.input}
                                                {...props.meta}
                                                src={values.signature && window.URL.createObjectURL(values.signature)}
                                                width={100}
                                                height={100}
                                                className={ classes.img }
                                                alt="Firma"/>
                                        </div>
                                    </div> :
                                    null
                            )}
                        </Field>
                        {
                            authState.roleId !== 3 ?
                                <>
                                    <Typography variant="subtitle2" className={ classes.subtitle }>Información Adicional</Typography>
                                    <Grid container spacing={3}>
                                        <Grid item sm={3} xs={12}>
                                            <TextField label="IMSS*" name="imss" inputProps={{onInput: toInputUppercase}} />
                                        </Grid>
                                        <Grid item sm={3} xs={12}>
                                            <TextField label="Clave INE*" name="ine_key" inputProps={{onInput: toInputUppercase}}/>
                                        </Grid>
                                        <Grid item sm={3} xs={12}>
                                            <TextField label="Folio INE*" name="ine_folio" inputProps={{onInput: toInputUppercase}}/>
                                        </Grid>
                                        <Grid item sm={3} xs={12}>
                                            <TextField label="Seccional*" name="ine_section" inputProps={{onInput: toInputUppercase}}/>
                                        </Grid>
                                    </Grid>
                                </> :
                                null
                        }
                        <Typography variant="subtitle2" className={ classes.subtitle }>Información de Domicilio</Typography>
                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={12}>
                                <TextField label="Calle y número*" name="street_address" inputProps={{onInput: toInputUppercase}}/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField label="Colonia*" name="suburb" inputProps={{onInput: toInputUppercase}}/>
                            </Grid>
                        </Grid>
                        <Typography variant="subtitle2" className={ classes.subtitle }>Información de Contacto</Typography>
                        <Grid container spacing={3}>
                            <Grid item sm={4} xs={12}>
                                <TextField label="Teléfono Fijo" name="home_phone" inputProps={{onInput: toInputUppercase}}/>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <TextField label="Teléfono Móvil" name="mobile_phone" inputProps={{onInput: toInputUppercase}}/>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <TextField label="Correo Electrónico" name="email" />
                            </Grid>
                        </Grid>
                        <Typography variant="subtitle2" className={ classes.subtitle }>Contactos de Emergencia</Typography>
                        {
                            values.emergency_contacts.length === 0 ?
                                <Message message="No ha agregado ningun contacto de emergencia, agregue por lo menos uno." style={{marginTop: 20}} /> :
                                null
                        }
                        <FieldArray name="emergency_contacts">
                            {({ fields }) => renderEmergencyContacts(fields, push, values, form)}
                        </FieldArray>
                        <div className={ classes.flexCenter } style={{marginTop: 20}}>
                            <Button
                                color="secondary"
                                type="button"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    push('emergency_contacts', {...defaultEmergencyContact});
                                }}
                            >
                                Agregar Contacto De Emergencia
                            </Button>
                        </div>
                        <Typography variant="subtitle2" className={ classes.subtitle }>Información de Envío</Typography>
                        {
                            !showEmployment ?
                                <Message
                                    message="¿Desea registrar la información del envío del trabajador?."
                                    hasAction={true}
                                    actionLabel="Agregar"
                                    onActionPress={() => setShowEmployment(true)}
                                    style={{marginTop: 20}}
                                /> :
                                <div className={classes.arrayFieldWrapper}>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <Typography variant="subtitle2">Envío Actual</Typography>
                                        <IconButton aria-label="delete" color="secondary" onClick={ () => {
                                            setShowEmployment(false);
                                        }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                    <Grid container spacing={1}>
                                        <Grid item sm={4} xs={12}>
                                            <Autocomplete
                                                label="Empresa*"
                                                name="company_id"
                                                options={companies}
                                                getOptionValue={option => option.id}
                                                getOptionLabel={option => option.name}
                                                disableCloseOnSelect={true}
                                            />
                                        </Grid>
                                        <Grid item sm={4} xs={12}>
                                            <TextField label="Puesto*" name={`job_position`} inputProps={{onInput: toInputUppercase}}/>
                                        </Grid>
                                        <Grid item sm={4} xs={12}>
                                            <KeyboardDatePicker label="Fecha de Inicio*" name="start_at" disableFuture={true} format={'DD-MM-YYYY'} disableToolbar={false}/>
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <TextField label="Planta*" name={`location`} inputProps={{onInput: toInputUppercase}}/>
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <TextField label="Turno*" name={`shift`} inputProps={{onInput: toInputUppercase}}/>
                                        </Grid>
                                    </Grid>
                                </div>
                        }
                        <div className={ classes.buttonWrapper }>
                            <Button color="secondary" type="submit" disabled={submitting} startIcon={ submitting ? <CircularProgress size={18}/> : null }>Crear</Button>
                        </div>
                        <MDialog fullScreen open={isSignatureDialogOpen} onClose={() => setIsSignatureDialogOpen(false)} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={() => setIsSignatureDialogOpen(false)} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Capturar Firma
                                    </Typography>
                                    <Button autoFocus color="inherit" onClick={() => signatureRef.current.clear()} style={{marginRight: 20}}>
                                        Limpiar
                                    </Button>
                                    <Button autoFocus color="inherit" onClick={() => handleSignatureDialogAccept(form)}>
                                        Guardar
                                    </Button>
                                </Toolbar>
                            </AppBar>
                            <SignatureCanvas
                                ref={signatureRef}
                                canvasProps={{width: window.innerWidth, height: window.innerHeight, className: 'sigCanvas'}}
                            />
                        </MDialog>
                    </form>
                )}
            />
            <Dialog
                title={'Alerta'}
                open={isDialogOpen}
                acceptLabel="Editar"
                onClose={() => setIsDialogOpen(false)}
                onAccept={ handleDialogAccept }>
                <DialogContentText id="alert-dialog-description">
                    Se encontró un trabajador con el CURP ingresado en el sistema, no puede crear un trabajador con el mismo CURP. Si el CURP ingresado es correcto, edite al trabajador en lugar de crearlo.
                </DialogContentText>
            </Dialog>
        </>
    );
};

export default withSnackbar(WorkersCreateForm);
