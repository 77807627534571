import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import axios from 'axios';
import {API_URL} from "../../../config";
import Table from "../../../components/Table/Table";
import {generateApiUrl} from "../../../helpers/helpers";
import {withSnackbar} from "notistack";
import Container from "@material-ui/core/Container";
import {COLOR_SECONDARY} from "../../../config/colors";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {NavLink} from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    id: {
        color: COLOR_SECONDARY,
        fontWeight: 'bold'
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    filterMenuWrapper: {
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 10,
        backgroundColor: '#FAFAFA'
    },
    filterMenuHeader: {
        marginLeft: 10,
        marginRight: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    }
}));

const PaymentsMissing = ({ history, enqueueSnackbar }) => {

    const classes = useStyles();

    const [quarters, setQuarters] = useState([]);
    const [selectedQuarter, setSelectedQuarter] = useState([]);

    // State de tabla
    const [selected, setSelected] = useState([]);

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);
    const [isInitLoading, setIsInitLoading] = useState(true);

    const [error, setError] = useState('');

    let [columnObject] = useState([
        {
            title: 'Trabajador',
            field: 'first_name',
            render: rowData => {
                return <NavLink to={`/workers/${rowData.worker_id}?referrer=payments`}><Typography
                    className={classes.id}>{rowData.first_name} {rowData.fathers_last_name} {rowData.mothers_last_name ? rowData.mothers_last_name : ''}</Typography></NavLink>;
            }
        },
        {
            title: 'CURP',
            field: 'curp'
        }
    ]);

    const tableRef = useRef(null);

    useEffect(() => {
        const init = async () => {
            await loadQuarters();
        };

        init();
    }, []);

    useEffect(() => {

        if(tableRef.current){
            // Cargar tabla
            tableRef.current.onQueryChange();
        }

    }, [selectedQuarter]);

    const loadQuarters = async () => {

        setIsInitLoading(true);

        try {

            const response = await axios.get(`${API_URL}/quarters`);

            setQuarters(response.data);
            setSelectedQuarter(response.data[response.data.length - 1].id);

        } catch(error){

            setError('Ocurrió un error cargando los trimestres.');

            // Mostrar mensaje de error
            enqueueSnackbar('Ocurrió un error cargando el trabajador.');

        }

        setIsInitLoading(false);
    };

    // Función que define los filtros y carga los registros
    const loadTableData = (query) => {

        return new Promise(async (resolve, reject) => {

            // Mostrar indicador de carga
            setIsLoading(true);

            // Generar el url
            let url = generateApiUrl(`${API_URL}/payments-missing`, query, null);
            url += '&quarter_id=' + selectedQuarter;

            try {

                // Realizar request
                const response = await axios.get(url);

                resolve({data: response.data.data, page: response.data.current_page - 1, totalCount: response.data.total});

            } catch(error){
                reject(new Error('Ocurrió un error cargando los registros.'))
            }

            // Esconder indicador de carga
            setIsLoading(false);

        }).catch((error) => {

            // Mostrar mensaje
            enqueueSnackbar('Ocurrió un error cargando los registros.');

        });
    };

    const renderQuarterOptions = () => {
        return quarters.map(quarter =>  (
            <MenuItem value={quarter.id}>{quarter.description}</MenuItem>
        ))
    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isInitLoading ?
                            <div className={classes.progress}>
                                <CircularProgress/>
                            </div> :
                            <div>
                                <Paper style={{paddingRight: 20, paddingLeft: 20, paddingTop: 10, paddingBottom: 20, marginBottom: 10}}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Trimestre</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedQuarter}
                                            label="Trimestre"
                                            onChange={(event) => setSelectedQuarter(event.target.value)}
                                        >
                                            { renderQuarterOptions() }
                                        </Select>
                                    </FormControl>
                                </Paper>
                                <Table
                                    title="Trabajadores sin Pago por Trimestre"
                                    tableRef={tableRef}
                                    loading={isLoading}
                                    columns={columnObject}
                                    loadTableData={(query) => loadTableData(query)}
                                    selected={selected}
                                    onSelect={(rows) => setSelected(rows)}
                                    canDelete={false}
                                    canEdit={false}
                                    canCreate={false}
                                />
                            </div>
                    }
                </div>
            </Container>
        </>
    );

};

export default withSnackbar(PaymentsMissing);
