import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import {withSnackbar} from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import UsersCreateForm from "../../../components/Forms/UsersCreateForm";
import CircularProgress from "@material-ui/core/CircularProgress";
import WarningIcon from "@material-ui/icons/Warning";
import {API_URL} from "../../../config";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
}));

const UsersCreate = ({ enqueueSnackbar }) => {

    const classes = useStyles();

    const [roles, setRoles] = useState([]);

    /* *** State de formulario *** */

    const formFields = {
        name: '',
        username: '',
        password: '',
        password_confirmation: '',
        role_id: ''
    };

    /* *** State de formulario *** */

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [error, setError] = useState('');

    useEffect(() => {

        const init = async () => {
            await loadRoles();
        };

        init();

    }, []);

    const loadRoles = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/roles`,
                {
                    params: {
                        sort: 'name',
                        direction: 'asc',
                        active: true
                    }
                }
            );

            // Actualizar state
            setRoles(response.data);

        } catch(error) {

            setError('Ocurrió un error cargando los roles.');

            enqueueSnackbar('Ocurrió un error cargando los roles.');

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isLoading ?
                            <div className={classes.progress}>
                                <CircularProgress/>
                            </div> :
                            error !== '' ?
                                <div className={classes.errorWrapper}>
                                    <WarningIcon color="primary" style={{marginRight: 8}}/>
                                    <Typography>{error}</Typography>
                                </div> :
                                <Paper className={classes.paper}>
                                    <LinearProgress style={{visibility: isSubmitting ? 'visible' : 'hidden'}}/>
                                    <Toolbar className={classes.padding}>
                                        <IconButton className={classes.backButton} component={NavLink} to="/users">
                                            <ArrowBackIcon color="secondary"/>
                                        </IconButton>
                                        <Typography className={classes.title} variant="h6" id="title">Nuevo
                                            Usuario</Typography>
                                    </Toolbar>
                                    <div>
                                        <div className={classes.padding}>
                                            <UsersCreateForm
                                                initialValues={formFields}
                                                roles={roles}
                                                submitting={isSubmitting}
                                                onSuccess={(message) => enqueueSnackbar(message)}
                                                onError={(message) => enqueueSnackbar(message)}
                                                onLoadingChange={(loading) => setIsSubmitting(loading)}
                                            />
                                        </div>
                                    </div>
                                </Paper>
                    }
                </div>
            </Container>
        </>
    )
};

export default withSnackbar(UsersCreate);
