import React from 'react';
import {Document, Font, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import MontserratRegular from '../../assets/fonts/Montserrat-Regular.ttf';
import MontserratBlack from '../../assets/fonts/Montserrat-Black.ttf';
import MontserratSemiBold from '../../assets/fonts/Montserrat-SemiBold.ttf';
import MontserratExtraBold from '../../assets/fonts/Montserrat-ExtraBold.ttf';
import MontserratMedium from '../../assets/fonts/Montserrat-Medium.ttf';
import UnifrakturRegular from '../../assets/fonts/UnifrakturMaguntia-Regular.ttf';
import RobotoMonoMedium from '../../assets/fonts/RobotoMono-Medium.ttf';
import moment from "moment";

import logo from '../../assets/images/logo_credencial.jpeg';

const Receipt = ({ payment }) => {

    moment.locale('es');

    Font.register({
        family: 'Unifraktur',
        format: "truetype",
        fonts: [
            { src: UnifrakturRegular, fontWeight: 400},
        ]
    });

    Font.register({
        family: 'RobotoMono',
        format: "truetype",
        fonts: [
            { src: RobotoMonoMedium, fontWeight: 400},
        ]
    });

    Font.register({
        family: 'Montserrat',
        format: "truetype",
        fonts: [
            { src: MontserratRegular, fontWeight: 400},
            { src: MontserratMedium, fontWeight: 500},
            { src: MontserratSemiBold, fontWeight: 700},
            { src: MontserratBlack, fontWeight: 800},
            { src: MontserratExtraBold, fontWeight: 900},
        ]
    });

    return <Document>
        <Page size={[595.28, 420.94]} style={styles.page}>
            <View style={[styles.flexRow, {marginHorizontal: 10}]}>
                <View>
                    <Image style={ styles.logo } src={ logo } />
                </View>
                <View style={{marginHorizontal: 10, flex: 1}}>
                    <Text style={[styles.headline, {textAlign: 'center'}]}>Sindicato de Jornaleros y Obreros Industriales</Text>
                    <View style={[styles.flexRow, styles.justifyAround]}>
                        <View style={[styles.flexColumn, styles.alignCenter]}>
                            <Text style={[styles.label]}>REGISTRO FEDERAL NO.</Text>
                            <Text style={[styles.label]}>REGISTRO ESTADO NO.</Text>
                            <Text style={[styles.label]}>R.F.C. SJO-320715-9X3</Text>
                        </View>
                        <View>
                            <Text style={styles.headline}>y de la Industria Maquiladora</Text>
                        </View>
                        <View style={[styles.flexColumn, styles.alignCenter]}>
                            <Text style={[styles.label]}>ITURBIDE Y 12 NO. 95</Text>
                            <Text style={[styles.label]}>TELS 813-46-35, 813-77-13, 813-77-01</Text>
                            <Text style={[styles.label]}>FAX: 812-40-62 APARTADO 767</Text>
                        </View>
                    </View>
                    <View style={{marginTop: -10}}>
                        <Text style={{fontSize: 9, textAlign: 'center', marginTop: 8, fontFamily: 'Montserrat', fontWeight: 900}}>FUNDADO EL 15 DE JULIO DE 1932</Text>
                        <Text style={{fontSize: 6, textAlign: 'center', marginTop: 3, fontFamily: 'Montserrat', fontWeight: 600}}>MIEMBRO DE LA FEDERACIÓN REGIONAL DE TRABAJADORES DE TAMAULIPAS</Text>
                    </View>
                </View>
                <View>
                    <Text style={[{fontFamily: 'Montserrat', fontWeight: 900, fontSize: 12}]}>F</Text>
                    <Text style={[{fontFamily: 'Montserrat', fontWeight: 900, fontSize: 12}, {marginLeft: 5}]}>T</Text>
                    <Text style={[{fontFamily: 'Montserrat', fontWeight: 900, fontSize: 12}, {marginLeft: 10}]}>T</Text>
                </View>
            </View>
            <View style={[styles.flexRow, styles.alignCenter, {marginHorizontal: 5}]}>
                <View style={[styles.boxed, styles.flexColumn, styles.alignCenter, {minWidth: 80}]}>
                    <Text style={[styles.label]}>No. AGREMIADO</Text>
                    <Text style={[styles.value]}>12345</Text>
                </View>
                <View style={[styles.boxed, styles.flexColumn, styles.alignCenter, {minWidth: 80}]}>
                    <Text style={[styles.label]}>RECIBO No.</Text>
                    <Text style={[styles.value]}>1</Text>
                </View>
            </View>
            <View style={[styles.flexRow, styles.alignCenter, {marginHorizontal: 5, marginTop: -1}]}>
                <View style={[styles.boxed, styles.flexColumn, styles.alignCenter, {minWidth: 80}]}>
                    <Text style={[styles.label]}>No. COMPAÑÍA</Text>
                    <Text style={[styles.value]}>1</Text>
                </View>
                <View style={[styles.boxed, styles.flexColumn, styles.alignCenter, {minWidth: 80}]}>
                    <Text style={[styles.label]}>FECHA</Text>
                    <Text style={[styles.value]}>02/Abr/2021</Text>
                </View>
            </View>
            <View style={[styles.boxed, {marginHorizontal: 5, marginTop: -1}]}>
                <View style={[styles.flexRow, styles.alignCenter, styles.justifyStart, {marginBottom: 3}]}>
                    <Text style={[styles.label, {marginRight: 5}]}>RECIBIMOS DEL C.</Text>
                    <Text style={[styles.value]}>ALAN ADOLFO ASTUDILLO OLIVARES</Text>
                </View>
                <View style={[styles.flexRow, styles.alignCenter, styles.justifyStart, {marginBottom: 3}]}>
                    <Text style={[styles.label, {marginRight: 5}]}>LA CANTIDAD DE</Text>
                    <Text style={[styles.value]}>30.00 MXN (TREINTA PESOS)</Text>
                </View>
                <View style={[styles.flexRow, styles.alignCenter, styles.justifyStart, {marginBottom: 3}]}>
                    <Text style={[styles.label, {marginRight: 5}]}>QUE LABORA EN</Text>
                    <Text style={[styles.value]}>ASTWARE SA DE CV</Text>
                </View>
            </View>
            <View style={[styles.tableWrapper, {marginTop: 10, marginHorizontal: 5}]}>
                <View style={styles.tableHeaderRow}>
                    <Text style={[styles.label, styles.tableHeaderCell, {width: '15%'}]}>CLAVE</Text>
                    <Text style={[styles.label, styles.tableHeaderCell, {width: '55%'}]}>CONCEPTO</Text>
                    <Text style={[styles.label, styles.tableHeaderCell, {width: '15%'}]}>P. UNITARIO</Text>
                    <Text style={[styles.label, styles.tableHeaderCell, {width: '15%'}]}>IMPORTE</Text>
                </View>
                <View style={[styles.tableBodyWrapper]}>
                    <View style={[styles.tableBodyRow]}>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}>1</Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '55%'}]}>CUOTA TRIMESTRAL</Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}>$10.00</Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}>$10.00</Text>
                    </View>
                    <View style={[styles.tableBodyRow]}>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}>1</Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '55%'}]}>CUOTA TRIMESTRAL</Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}>$10.00</Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}>$10.00</Text>
                    </View>
                    <View style={[styles.tableBodyRow]}>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}>2</Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '55%'}]}>CUOTA ANUAL</Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}>$10.00</Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}>$10.00</Text>
                    </View>
                    <View style={[styles.tableBodyRow]}>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}></Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '55%'}]}></Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}></Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}></Text>
                    </View>
                    <View style={[styles.tableBodyRow]}>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}></Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '55%'}]}></Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}></Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}></Text>
                    </View>
                    <View style={[styles.tableBodyRow]}>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}></Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '55%'}]}></Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}></Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '15%'}]}></Text>
                    </View>
                </View>
                <View style={styles.tableBodyWrapper}>
                    <View style={[styles.tableBodyRow]}>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '60%'}]}>ESTA ES UNA NOTA DEL RECIBO DE PAGO</Text>
                        <Text style={[styles.tableBodyCell, styles.value, {width: '40%', textAlign: 'right'}]}>Importe: $30.00</Text>
                    </View>
                </View>
            </View>
            <View style={[styles.flexRow, styles.justifyAround, {marginTop: 20}]}>
                <View style={[styles.flexColumn, styles.alignCenter]}>
                    <Text style={[styles.value]}>C. JUAN VILLAFUERTE MORALES</Text>
                    <Text style={[styles.label]}>Vo. Bo. EL SECRETARIO GENERAL</Text>
                </View>
                <View style={[styles.flexColumn, styles.alignCenter]}>
                    <Text style={[styles.value]}>C. JESUS DE LOS ANGELES CABALLERO DUARTE</Text>
                    <Text style={[styles.label]}>Vo. Bo. EL SECRETARIO TESORERO</Text>
                </View>
            </View>
        </Page>
    </Document>

};

const styles = StyleSheet.create({
    page: {
        paddingVertical: 20,
        backgroundColor: '#e6fee6'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between'
    },
    alignCenter: {
        alignItems: 'center'
    },
    justifyStart: {
        justifyContent: 'flex-start'
    },
    justifyAround: {
        justifyContent: 'space-around'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'space-between'
    },
    paddingH10: {
        paddingRight: 10
    },
    headline: {
        fontSize: 16,
        fontFamily: 'Unifraktur'
    },
    logo: {
        width: 50,
        objectFit: 'contain'
    },
    boxed: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#8a9f94',
        padding: 2,
        backgroundColor: '#cff9e7'
    },
    label: {
        fontSize: 6,
        color: '#334e3a',
        fontFamily: 'Montserrat',
        fontWeight: 900
    },
    value: {
        fontSize: 8,
        fontFamily: 'RobotoMono',
        color: '#bacdc1'
    },
    tableWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: '#cff9e7'
    },
    tableHeaderRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#18ba8e'
    },
    tableHeaderCell: {
        color: 'white',
        textAlign: 'center',
        paddingHorizontal: 10,
        paddingVertical: 4,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#90d6be'
    },
    tableBodyWrapper: {
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#90d6be',
        borderLeftStyle: 'solid',
        borderLeftWidth: 1,
        borderLeftColor: '#90d6be'
    },
    tableBodyRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    tableBodyCell: {
        fontWeight: 500,
        paddingHorizontal: 10,
        paddingVertical: 4,
        borderRightStyle: 'solid',
        borderRightWidth: 1,
        borderRightColor: '#90d6be',
        height: 20
    },
});

export default Receipt;
