import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import axios from "axios";
import WarningIcon from "@material-ui/icons/Warning";
import {withSnackbar} from "notistack";
import Container from "@material-ui/core/Container";
import CompaniesEditForm from "../../../components/Forms/CompaniesEditForm";
import {API_URL} from "../../../config";
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    iconError: {
        color: theme.palette.error.main
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

const CompaniesEdit = ({ match, enqueueSnackbar }) => {

    const classes = useStyles();

    // ID del registro
    const id = match.params.id;

    /* *** State de formulario *** */

    const [formFields, setFormFields] = useState({
        name: '',
        street_address: '',
        suburb: '',
        contact_name: '',
        contact_phone: '',
        contact_email: '',
        start_at: '',
        end_at: '',
        id
    });

    /* *** State de formulario *** */

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [error, setError] = useState('');

    useEffect(() => {

        const init = async () => {
            await loadCompany();
        };

        init();

    }, []);

    // Función que carga la empresa
    const loadCompany = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/companies/${id}`);
            const companyData = response.data;

            companyData.start_at = companyData.start_at && moment(companyData.start_at, 'YYYY-MM-DD');
            companyData.end_at = companyData.end_at && moment(companyData.end_at, 'YYYY-MM-DD');

            setFormFields(companyData);

        } catch(error){

            setError('Ocurrió un error cargando el registro.');

            enqueueSnackbar('Ocurrió un error cargando el registro.');

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isLoading ?
                            <div className={ classes.progress }>
                                <CircularProgress />
                            </div> :
                            error !== '' ?
                                <div className={ classes.errorWrapper }>
                                    <WarningIcon color="primary" style={ { marginRight: 8 } }/>
                                    <Typography>{ error }</Typography>
                                </div> :
                                <Paper className={classes.paper}>
                                    <Toolbar className={classes.padding}>
                                        <IconButton className={ classes.backButton } component={ NavLink } to="/companies">
                                            <ArrowBackIcon color="secondary" />
                                        </IconButton>
                                        <Typography className={classes.title} variant="h6" id="title">Editar Empresa</Typography>
                                    </Toolbar>
                                    <div>
                                        <div className={classes.padding}>
                                            <CompaniesEditForm
                                                initialValues={ formFields }
                                                submitting={isSubmitting}
                                                onSuccess={ (message) => enqueueSnackbar(message) }
                                                onError={ (message) => enqueueSnackbar(message) }
                                                onLoadingChange={ (loading) => setIsSubmitting(loading)}
                                            />
                                        </div>
                                    </div>
                                </Paper>
                    }
                </div>
            </Container>
        </>
    )
};

export default withSnackbar(CompaniesEdit);
