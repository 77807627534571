import React from 'react';
import {Document, Page, Text, StyleSheet, View, Image, Font} from "@react-pdf/renderer";
import logo from '../../assets/images/logo_credencial.jpeg';
import placeholder from '../../assets/images/credencial_muestra.jpg';
import decoration from '../../assets/images/decoracion_credencial.png';
import background from '../../assets/images/fondo_credencial.png';
import MontserratRegular from '../../assets/fonts/Montserrat-Regular.ttf';
import MontserratBlack from '../../assets/fonts/Montserrat-Black.ttf';
import MontserratSemiBold from '../../assets/fonts/Montserrat-SemiBold.ttf';
import MontserratExtraBold from '../../assets/fonts/Montserrat-ExtraBold.ttf';
import MontserratMedium from '../../assets/fonts/Montserrat-Medium.ttf';
import moment from "moment";

const Credentials = ({ data }) => {

    moment.locale('es');

    Font.register({
        family: 'Montserrat',
        format: "truetype",
        fonts: [
            { src: MontserratRegular, fontWeight: 400},
            { src: MontserratMedium, fontWeight: 500},
            { src: MontserratSemiBold, fontWeight: 700},
            { src: MontserratBlack, fontWeight: 800},
            { src: MontserratExtraBold, fontWeight: 900},
        ]
    });

    const renderCredentials = () => {
        return data.map(worker => (
            <Page size={{ width: 3.375 * 72, height: 2.125 * 72 }} key={worker.id} orientation="portrait">
                <Image style={ styles.pageBackground } src={background} />
                <Image style={ styles.pageBackground } src={decoration} />
                <View style={styles.wrapper}>
                    <View style={{backgroundColor: '#fa6803', height: 4, marginBottom: 2}}></View>
                    <View style={{backgroundColor: '#fda401', height: 1, marginBottom: 4}}></View>
                    <View style={{flex: 1}}>
                        <View style={styles.headerWrapper}>
                            <Image style={ styles.logo } src={logo} />
                            <View style={styles.headerTitleWrapper}>
                                <Text style={styles.headerTitle}>Sindicato de Jornaleros y Obreros Industriales y de la Industria Maquiladora</Text>
                                {/*<View style={styles.headerAddressWrapper}>*/}
                                {/*    <Text style={styles.headerLabel}>ITURBIDE Y 12 No. 95</Text>*/}
                                {/*    <Text style={styles.headerLabel}>ZONA CENTRO</Text>*/}
                                {/*    <Text style={styles.headerLabel}>H. MATAMOROS, TAM.</Text>*/}
                                {/*</View>*/}
                                <Text style={[styles.headerLabel, {textAlign: 'center'}]}>LA PRESENTE ACREDITA COMO AGREMIADO ACTIVO DEL <Text style={{fontWeight: 900}}>SJOIIM</Text></Text>
                            </View>
                        </View>
                        <View style={styles.mainContentWrapper}>
                            <View style={styles.leftContentWrapper}>
                                <View style={{backgroundColor: '#fda401'}}>
                                    <Image style={ styles.photo } src={ worker.image ? `data:image/${worker.image_type};base64,${worker.image_64}` : placeholder } />
                                </View>
                                <Text style={styles.workerNumberLabel}>#{worker.worker_number}</Text>
                                {
                                    worker.barcode ?
                                        <Image style={ styles.barcode } src={ worker.barcode } /> :
                                        null
                                }
                            </View>
                            <View style={styles.centerContentWrapper}>
                                <Text style={styles.mainLabel}>{ `${worker.first_name} ${worker.fathers_last_name} ${worker.mothers_last_name ? worker.mothers_last_name : ''}` }</Text>
                                <Text style={styles.label}>{ `${worker.street_address} ${worker.suburb}` }</Text>
                                <Text style={styles.label}>{ worker.curp }</Text>
                                <Text style={styles.label}>{ worker.imss }</Text>
                                {
                                    worker.home_phone || worker.mobile_phone ?
                                        <Text style={styles.label}>{ worker.home_phone || worker.mobile_phone }</Text> :
                                        null
                                }
                                {
                                    worker.company ?
                                        <Text style={styles.secondaryLabel}>{ worker.company ? worker.company.name : '' }</Text> :
                                        null
                                }
                            </View>
                            <Image style={ styles.qr } src={ worker.qr } />
                        </View>
                    </View>
                    <View style={styles.footerWrapper}>
                        <Text style={styles.footerText}>FECHA DE EXPEDICIÓN</Text>
                        <Text style={styles.footerTextHighlight}>{ moment().format('DD [DE] MMMM [DEL] YYYY') }</Text>
                        <View style={{backgroundColor: '#fda401', height: 1, marginVertical: 2}}></View>
                    </View>
                </View>
            </Page>
        ));
    };

    return <Document>
        { renderCredentials() }
    </Document>

};

const styles = StyleSheet.create({
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    headerWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        paddingHorizontal: 4,
        marginTop: 2
    },
    logo: { width: 35 },
    headerTitleWrapper: {
        flex: 1,
        marginLeft: 5
    },
    headerTitle: {
        fontSize: 8,
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontWeight: 900
    },
    headerAddressWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        marginTop: 2
    },
    headerLabel: {
        marginHorizontal: 2,
        fontSize: 4,
        fontFamily: 'Montserrat',
        fontWeight: 700,
        marginTop: 4
    },
    mainContentWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'row',
        marginHorizontal: 12,
        marginTop: 6
    },
    leftContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    photo: {
        width: 50,
        height: 52,
        objectFit: 'cover',
        marginLeft: 1,
        marginBottom: 1,
        marginTop: -1,
        marginRight: -1
    },
    qr: {
        width: 40,
        height: 40,
        marginLeft: 8
    },
    barcode: {
        width: 30,
        height: 10
    },
    workerNumberLabel: {
        marginVertical: 3,
        fontSize: 5,
        fontFamily: 'Montserrat',
        fontWeight: 900
    },
    label1: {
        fontSize: 7
    },
    centerContentWrapper: {
        flex: 1,
        marginLeft: 10
    },
    mainLabel: {
        fontSize: 7,
        fontFamily: 'Montserrat',
        fontWeight: 900
    },
    secondaryLabel: {
        fontSize: 5,
        marginTop: 3,
        borderBottomStyle: 'solid',
        borderBottomColor: '#fda401',
        borderBottomWidth: 1,
        fontFamily: 'Montserrat',
        fontWeight: 700
    },
    label: {
        fontSize: 5,
        marginTop: 3,
        borderBottomStyle: 'solid',
        borderBottomColor: '#fda401',
        borderBottomWidth: 1,
        fontFamily: 'Montserrat',
        fontWeight: 400
    },
    footerWrapper: {
        backgroundColor: '#fa6803',
        paddingTop: 4,
    },
    footerText: {
        color: 'white',
        fontSize: 4,
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontWeight: 500
    },
    footerTextHighlight: {
        color: 'white',
        fontSize: 4,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontFamily: 'Montserrat',
        fontWeight: 900
    }
});

export default Credentials;
