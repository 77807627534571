import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {withSnackbar} from "notistack";
import Container from "@material-ui/core/Container";
import PaymentsCreateForm from "../../../components/Forms/PaymentsCreateForm";
import axios from "axios";
import {API_URL} from "../../../config";
import WarningIcon from "@material-ui/icons/Warning";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import moment from "moment";
import PersonIcon from '@material-ui/icons/Person';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import BusinessIcon from '@material-ui/icons/Business';
import {COLOR_PRIMARY} from "../../../config/colors";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
    chip: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: COLOR_PRIMARY,
        borderRadius: 33,
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 8,
        paddingLeft: 8,
    }
}));

const PaymentsCreate = ({ match, enqueueSnackbar }) => {

    const classes = useStyles();

    // ID del documento
    const id = match.params.id;
    const quarterId = match.params.quarter;

    /* *** State de formulario *** */

    const [formFields, setFormFields] = useState({
        paid_at: moment(),
        quarter_id: quarterId,
        worker_id: id,
        checkout_id: null,
        notes: '',
        payment_fees: []
    });

    /* *** State de formulario *** */

    const [fees, setFees] = useState([]);
    const [worker, setWorker] = useState(null);
    const [quarter, setQuarter] = useState(null);

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [error, setError] = useState('');

    useEffect(() => {

        const init = async () => {
            await loadWorker();
            await loadQuarter();
            await loadFees();
        };

        init();

    }, []);

    const loadWorker = async () => {

        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/workers/${id}`
            );

            setWorker(response.data.worker);

        } catch(error) {

            enqueueSnackbar('Ocurrió un error cargando la información del trabajador.');

        }

        setIsLoading(false);

    };

    const loadQuarter = async () => {

        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/quarters/${quarterId}`
            );

            setQuarter(response.data);

        } catch(error) {

            enqueueSnackbar('Ocurrió un error cargando la información del trimestre.');

        }

        setIsLoading(false);

    };

    const loadFees = async () => {

        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/fees`,
                {
                    params: {
                        sort: 'name',
                        direction: 'asc',
                        active: true
                    }
                }
            );

            setFees(response.data);
            setFormFields({...formFields, payment_fees: response.data.map((fee) => ({id: fee.id, price: fee.price, checked: false}))})

        } catch(error) {

            enqueueSnackbar('Ocurrió un error cargando los conceptos.');

        }

        setIsLoading(false);

    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isLoading ?
                            <div className={classes.progress}>
                                <CircularProgress/>
                            </div> :
                            error !== '' ?
                                <div className={classes.errorWrapper}>
                                    <WarningIcon color="primary" style={{marginRight: 8}}/>
                                    <Typography>{error}</Typography>
                                </div> :
                                <div>
                                    {
                                        worker && quarter ?
                                            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 16}}>
                                                <div className={classes.chip} style={{marginRight: 16}}>
                                                    <PersonIcon color="primary" style={{marginRight: 8}} />
                                                    <Typography variant="body2" style={{color: COLOR_PRIMARY}}>{worker.first_name + ' ' + worker.fathers_last_name}</Typography>
                                                </div>
                                                <div className={classes.chip} style={{marginRight: 16}}>
                                                    <CalendarTodayIcon color="primary" style={{marginRight: 8}} />
                                                    <Typography variant="body2" style={{color: COLOR_PRIMARY}}>{quarter.description}</Typography>
                                                </div>
                                                <div className={classes.chip} style={{marginRight: 16}}>
                                                    <AccountCircleIcon color="primary" style={{marginRight: 8}} />
                                                    <Typography variant="body2" style={{color: COLOR_PRIMARY}}>{worker.curp}</Typography>
                                                </div>
                                                <div className={classes.chip} style={{marginRight: 16}}>
                                                    <BusinessIcon color="primary" style={{marginRight: 8}} />
                                                    <Typography variant="body2" style={{color: COLOR_PRIMARY}}>{worker.company && worker.company.name}</Typography>
                                                </div>
                                                <div className={classes.chip} style={{marginRight: 16}}>
                                                    <AccessTimeIcon color="primary" style={{marginRight: 8}} />
                                                    <Typography variant="body2" style={{color: COLOR_PRIMARY}}>Turno {worker.company && worker.company.pivot.shift}</Typography>
                                                </div>
                                            </div> :
                                            null
                                    }
                                    <PaymentsCreateForm
                                        initialValues={formFields}
                                        fees={fees}
                                        submitting={isSubmitting}
                                        onSuccess={(message) => enqueueSnackbar(message)}
                                        onError={(message) => enqueueSnackbar(message)}
                                        onLoadingChange={(loading) => setIsSubmitting(loading)}
                                    />
                                </div>
                    }
                </div>
            </Container>
        </>
    )
};

export default withSnackbar(PaymentsCreate);
