import Receipt from "../components/Documents/Receipt";
import {PDFViewer} from "@react-pdf/renderer";
import React from "react";

const CompaniesIndex = () => {
    return <PDFViewer height={1000}>
        <Receipt />
    </PDFViewer>
};

export default CompaniesIndex;
