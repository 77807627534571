import React from 'react';
import {Form} from "react-final-form";
import {DatePicker, KeyboardDatePicker, TextField} from "mui-rff";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import validate from "validate.js";
import axios from "axios";
import {API_URL} from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import {toInputUppercase} from "../../helpers/helpers";
import createDecorator from "final-form-focus";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    subtitle: {
        marginTop: theme.spacing(2)
    }
}));

validate.validators.customDate = function(value, options, key, attributes) {
    return moment.isMoment(value) && value.isValid() ? null : "Debe de ingresar una fecha válida";
};

const CompaniesCreateForm = ({ initialValues, onSuccess, onError, onLoadingChange, submitting }) => {

    const classes = useStyles();

    const focusOnErrors = createDecorator();

    // Reglas de validación de formulario
    const constraints = {
        name: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        contact_email: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    email: {
                        message: 'Debe de ingresar un correo válido'
                    }
                }
            } else {
                return null;
            }
        },
        contact_phone: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    numericality: {
                        onlyInteger: true,
                        notValid: 'El número de teléfono solo debe contener números.',
                    },
                    length: {
                        is: 10,
                        notValid: 'El número de teléfono debe contener 10 dígitos.',
                        wrongLength: 'El número de teléfono debe contener 10 dígitos.',
                    }
                }
            } else {
                return null;
            }
        },
        start_at: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    customDate: {
                        message: "Debe de ingresar una fecha válida"
                    }
                }
            } else {
                return null;
            }
        },
        end_at: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    customDate: {
                        message: "Debe de ingresar una fecha válida"
                    }
                }
            } else {
                return null;
            }
        },
    };

    // Función que se ejecuta al enviar formulario
    async function onSubmit(values, form) {

        // Mostrar indicador de carga
        onLoadingChange(true);

        try {

            // Datos a enviar
            const data = {
                name: values.name,
                street_address: values.street_address,
                suburb: values.suburb,
                contact_name: values.contact_name,
                contact_email: values.contact_email,
                contact_phone: values.contact_phone,
                start_at: values.start_at && values.start_at.format('YYYY-MM-DD'),
                end_at: values.end_at && values.end_at.format('YYYY-MM-DD')
            };

            // Enviar datos a API
            await axios.post(
                `${API_URL}/companies`,
                data
            );

            setTimeout(form.restart);

            // Enviar mensaje de éxito a componente padre
            onSuccess('Empresa creada.');

            // Esconder indicador de carga
            onLoadingChange(false);

        } catch (error) {

            // Esconder indicador de carga
            onLoadingChange(false);

            // Dependiendo del error, mostrar mensajes
            switch(error.response.status){
                case 400:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                case 422:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                default:
                    // Mostrar mensaje
                    onError("Ocurrió un error desconocido.");
                    break;
            }

        }

    }

    // Función que valida el formulario
    async function validateForm(values) {

        // Validar campos
        let valid = validate({
            name: values.name,
            street_address: values.street_address,
            suburb: values.suburb,
            contact_name: values.contact_name,
            contact_phone: values.contact_phone,
            contact_email: values.contact_email,
            start_at: values.start_at,
            end_at: values.end_at
        }, constraints, {fullMessages: false});

        // Si no hay error
        if(!valid)
            return;
        else {

            // Modificar objeto para mostrar errores en formulario
            Object.keys(valid).forEach((key, index) => {
                valid[key] = valid[key][0];
            });

            return valid;
        }
    }

    // Función que manipula objeto para mostrar errores
    const handleServerError = (error) => {

        Object.keys(error).forEach((key, index) => {
            error[key] = error[key][0];
        });

        return error;
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            decorators={[ focusOnErrors ]}
            validate={validateForm}
            render={({ handleSubmit, values, submitError, form }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Typography variant="subtitle2" className={ classes.subtitle }>Información General</Typography>
                    <TextField label="Nombre*" name="name" inputProps={{onInput: toInputUppercase}} style={{marginTop: 20}} />
                    <Grid container spacing={3} style={{marginTop: 20}}>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Calle y número" name="street_address" inputProps={{onInput: toInputUppercase}} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Colonia" name="suburb" inputProps={{onInput: toInputUppercase}} />
                        </Grid>
                    </Grid>
                    <Typography variant="subtitle2" className={ classes.subtitle }>Información de Contacto</Typography>
                    <Grid container spacing={3} style={{marginTop: 10}}>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Nombre de Contacto" name="contact_name" inputProps={{onInput: toInputUppercase}} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Teléfono de Contacto" name="contact_phone" inputProps={{onInput: toInputUppercase}} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Correo de Contacto" name="contact_email" />
                        </Grid>
                    </Grid>
                    <Typography variant="subtitle2" className={ classes.subtitle }>Información de Afiliación</Typography>
                    <Grid container spacing={3} style={{marginTop: 20}}>
                        <Grid item sm={6} xs={12}>
                            <DatePicker label="Fecha de Alta" name="start_at" disableFuture={true} format={'MM-DD-YYYY'} disableToolbar={false}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <DatePicker label="Fecha de Baja" name="end_at" disableFuture={true} format={'MM-DD-YYYY'} disableToolbar={false}/>
                        </Grid>
                    </Grid>
                    <pre>
                        {
                            JSON.stringify(values, 0, 2)
                        }
                    </pre>
                    <div className={ classes.buttonWrapper }>
                        <Button color="secondary" type="submit" disabled={submitting} startIcon={ submitting ? <CircularProgress size={18}/> : null }>Crear</Button>
                    </div>
                </form>
            )}
        />
    );
};

export default CompaniesCreateForm;
