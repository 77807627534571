import React from 'react';
import {Form} from "react-final-form";
import {Select, TextField} from "mui-rff";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import validate from "validate.js";
import axios from "axios";
import {API_URL} from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import {toInputUppercase} from "../../helpers/helpers";
import createDecorator from "final-form-focus";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
}));

const UsersCreateForm = ({ initialValues, roles, onSuccess, onError, onLoadingChange, submitting }) => {

    const classes = useStyles();

    const focusOnErrors = createDecorator();

    // Reglas de validación de formulario
    const constraints = {
        name: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        username: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        password: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            },
            length: {
                minimum: 6,
                message: 'La contraseña debe contener almenos 6 caracteres'
            }
        },
        password_confirmation: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            },
            equality: {
                attribute: "password",
                message: 'La contraseña no coincide'
            }
        },
        role_id: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            },
            inclusion: {
                within: roles.map(role => role.id),
                message: 'Debe seleccionar un valor del listado'
            }
        }
    };

    // Función que se ejecuta al enviar formulario
    async function onSubmit(values, form) {

        // Mostrar indicador de carga
        onLoadingChange(true);

        try {

            // Datos a enviar
            const data = {
                name: values.name,
                username: values.username,
                password: values.password,
                password_confirmation: values.password_confirmation,
                role_id: values.role_id
            };

            // Enviar datos a API
            await axios.post(
                `${API_URL}/users`,
                data
            );

            setTimeout(form.restart);

            // Enviar mensaje de éxito a componente padre
            onSuccess('Usuario creado.');

            // Esconder indicador de carga
            onLoadingChange(false);

        } catch (error) {

            // Esconder indicador de carga
            onLoadingChange(false);

            // Dependiendo del error, mostrar mensajes
            switch(error.response.status){
                case 400:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                case 422:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                default:
                    // Mostrar mensaje
                    onError("Ocurrió un error desconocido.");
                    break;
            }

        }

    }

    // Función que valida el formulario
    async function validateForm(values) {

        // Validar campos
        let valid = validate({
            name: values.name,
            username: values.username,
            password: values.password,
            password_confirmation: values.password_confirmation,
            role_id: values.role_id
        }, constraints, {fullMessages: false});

        // Si no hay error
        if(!valid)
            return;
        else {

            // Modificar objeto para mostrar errores en formulario
            Object.keys(valid).forEach((key, index) => {
                valid[key] = valid[key][0];
            });

            return valid;
        }
    }

    // Función que manipula objeto para mostrar errores
    const handleServerError = (error) => {

        Object.keys(error).forEach((key, index) => {
            error[key] = error[key][0];
        });

        return error;
    };

    // Opciones de select
    const rolesOptions = roles.map(role => (
        <MenuItem key={role.id} value={role.id}>{ role.name }</MenuItem>
    ));

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            decorators={[ focusOnErrors ]}
            validate={validateForm}
            render={({ handleSubmit, values, submitError, form }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Nombre" name="name" inputProps={{onInput: toInputUppercase}} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Usuario" name="username" inputProps={{onInput: toInputUppercase}} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Contraseña" name="password" type="password"/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Confirmar Contraseña" name="password_confirmation" type="password"/>
                        </Grid>
                    </Grid>
                    <Select name="role_id" label="Rol" formControlProps={{ margin: 'normal' }}>
                        { rolesOptions }
                    </Select>
                    <div className={ classes.buttonWrapper }>
                        <Button color="secondary" type="submit" disabled={submitting} startIcon={ submitting ? <CircularProgress size={18}/> : null }>Crear</Button>
                    </div>
                </form>
            )}
        />
    );
};

export default UsersCreateForm;
