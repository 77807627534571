import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import {withSnackbar} from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import WorkerMovementsEditForm from "../../../components/Forms/WorkerMovementsEditForm";
import axios from "axios";
import {API_URL} from "../../../config";
import * as lodash from "lodash";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
}));

const WorkerMovementsEdit = ({ match, enqueueSnackbar, history }) => {

    const classes = useStyles();

    // ID del registro
    const [id, setId] = useState(match.params.id);

    const [worker, setWorker] = useState('');
    const [company, setCompany] = useState('');

    /* *** State de formulario *** */

    const [formFields, setFormFields] = useState({
        job_position: '',
        location: '',
        shift: '',
        start_at: '',
        end_at: '',
        id
    });

    /* *** State de formulario *** */

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [error, setError] = useState('');

    useEffect(() => {

        const init = async () => {
            await loadWorkerMovement(id);
        };

        init();

    }, []);

    // Función que carga el movimiento del trabajador
    const loadWorkerMovement = async (id) => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/worker_movements/${id}`);
            let data = response.data;

            let workerData = data.worker;
            let companyData = data.company;

            let companyWorkerData = lodash.mapValues(data.company_worker, v => v === null ? '' : v);

            companyWorkerData.start_at = moment(companyWorkerData.start_at, 'YYYY-MM-DD');
            companyWorkerData.end_at = companyWorkerData.end_at && moment(companyWorkerData.end_at, 'YYYY-MM-DD');

            setWorker(`${workerData.first_name} ${workerData.fathers_last_name} ${workerData.mothers_last_name ? workerData.mothers_last_name : ''}`);
            setCompany(companyData.name);

            setFormFields(companyWorkerData);

        } catch(error){

            setError('Ocurrió un error cargando el registro.');

            enqueueSnackbar('Ocurrió un error cargando el registro.');

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const handleRouteChange = (id) => {
        setId(id);
        loadWorkerMovement(id);
    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isLoading ?
                            <div className={classes.progress}>
                                <CircularProgress/>
                            </div> :
                            error !== '' ?
                                <div className={classes.errorWrapper}>
                                    <WarningIcon color="primary" style={{marginRight: 8}}/>
                                    <Typography>{error}</Typography>
                                </div> :
                                <Paper className={classes.paper}>
                                    <LinearProgress style={{visibility: isSubmitting ? 'visible' : 'hidden'}}/>
                                    <Toolbar className={classes.padding}>
                                        <IconButton className={classes.backButton} component={NavLink}
                                                    to="/worker_movements">
                                            <ArrowBackIcon color="secondary"/>
                                        </IconButton>
                                        <Typography className={classes.title} variant="h6" id="title">Editar
                                            Movimiento</Typography>
                                    </Toolbar>
                                    <div>
                                        <div className={classes.padding}>
                                            <WorkerMovementsEditForm
                                                initialValues={formFields}
                                                submitting={isSubmitting}
                                                worker={worker}
                                                company={company}
                                                onRouteChange={handleRouteChange}
                                                onSuccess={(message) => enqueueSnackbar(message)}
                                                onError={(message) => enqueueSnackbar(message)}
                                                onLoadingChange={(loading) => setIsSubmitting(loading)}
                                                history={history}
                                            />
                                        </div>
                                    </div>
                                </Paper>
                    }
                </div>
            </Container>
        </>
    )
};

export default withSnackbar(WorkerMovementsEdit);
