import React, {useState} from 'react';
import {Form} from "react-final-form";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import validate from "validate.js";
import axios from "axios";
import {API_URL} from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {withSnackbar} from "notistack";
import {KeyboardDatePicker, TextField} from "mui-rff";
import Dialog from "../Dialog/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiTextField from "@material-ui/core/TextField";
import {toInputUppercase} from "../../helpers/helpers";
import createDecorator from "final-form-focus";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    subtitle: {
        marginTop: theme.spacing(2)
    }
}));

validate.validators.customDate = function(value, options, key, attributes) {
    return moment.isMoment(value) && value.isValid() ? null : "Debe de ingresar una fecha válida";
};

const WorkerMovementsEditForm = ({ initialValues, company, worker, onRouteChange, onSuccess, onError, onLoadingChange, submitting, enqueueSnackbar, history }) => {

    const classes = useStyles();

    const focusOnErrors = createDecorator();

    const [currentCompanyId, setCurrentCompanyId] = useState('');

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    // Reglas de validación de formulario
    const constraints = {
        job_position: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        location: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        shift: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        start_at: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            },
            customDate: {
                message: "Debe de ingresar una fecha válida"
            }
        },
        end_at: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    customDate: {
                        message: "Debe de ingresar una fecha válida"
                    }
                }
            } else {
                return null;
            }
        }
    };

    // Función que se ejecuta al enviar formulario
    async function onSubmit(values, form) {

        // Mostrar indicador de carga
        onLoadingChange(true);

        setCurrentCompanyId('');

        try {

            // Hacer request
            const response = await axios.get(`${API_URL}/workers/${values.worker_id}`);

            if(response.data.worker.company && response.data.worker.company.pivot.id !== values.id && !values.end_at){

                setCurrentCompanyId(response.data.worker.company.pivot.id);

                setIsDialogOpen(true);

                onLoadingChange(false);

                return;
            }

            // Datos a enviar
            const data = { ...values };

            data.start_at = data.start_at.format('YYYY-MM-DD');
            data.end_at = data.end_at && data.end_at.format('YYYY-MM-DD');

            // Enviar datos a API
            await axios.put(
                `${API_URL}/worker_movements/${values.id}`,
                data
            );

            // Enviar mensaje de éxito a componente padre
            onSuccess('Movimiento actualizado.');

            // Esconder indicador de carga
            onLoadingChange(false);

        } catch (error) {

            // Esconder indicador de carga
            onLoadingChange(false);

            // Dependiendo del error, mostrar mensajes
            switch(error.response.status){
                case 400:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                case 422:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                default:
                    // Mostrar mensaje
                    onError("Ocurrió un error desconocido.");
                    break;
            }

        }

    }

    // Función que valida el formulario
    async function validateForm(values) {

        // Validar campos
        let valid = validate({ ...values }, constraints, {fullMessages: false});

        // Si no hay error
        if(!valid)
            return;
        else {

            // Modificar objeto para mostrar errores en formulario
            Object.keys(valid).forEach((key, index) => {
                valid[key] = valid[key][0];
            });

            return valid;
        }
    }

    // Función que manipula objeto para mostrar errores
    const handleServerError = (error) => {

        Object.keys(error).forEach((key, index) => {
            error[key] = error[key][0];
        });

        return error;
    };

    const handleDialogAccept = () => {

        setIsDialogOpen(false);

        history.push(`/worker_movements/${currentCompanyId}/edit`);

        onRouteChange(currentCompanyId);
    };

    return <>
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            decorators={[ focusOnErrors ]}
            validate={validateForm}
            render={({ handleSubmit, values, submitError, form }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <MuiTextField label="Trabajador*" value={worker} disabled fullWidth />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <MuiTextField label="Empresa*" value={company} disabled fullWidth />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <KeyboardDatePicker label="Fecha de Ingreso*" name="start_at" disableFuture={true} format={'DD-MM-YYYY'} disableToolbar={false}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <KeyboardDatePicker label="Fecha de Salida*" name="end_at" disableFuture={true} format={'DD-MM-YYYY'} disableToolbar={false}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Puesto*" name="job_position" inputProps={{onInput: toInputUppercase}}/>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Planta*" name="location" inputProps={{onInput: toInputUppercase}}/>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Turno*" name="shift" inputProps={{onInput: toInputUppercase}}/>
                        </Grid>
                    </Grid>
                    <div className={ classes.buttonWrapper }>
                        <Button color="secondary" type="submit" disabled={submitting} startIcon={ submitting ? <CircularProgress size={18}/> : null }>Actualizar</Button>
                    </div>
                </form>
            )}
        />
        <Dialog
            title={'Alerta'}
            open={isDialogOpen}
            acceptLabel="Ver movimiento"
            onClose={() => setIsDialogOpen(false)}
            onAccept={ handleDialogAccept }>
            <DialogContentText id="alert-dialog-description">
                El trabajador seleccionado no ha sido dado de baja de la empresa en la que se encuentra actualmente. Antes de asignarlo a una nueva empresa, debe de darlo de baja.
            </DialogContentText>
        </Dialog>
    </>;
};

export default withSnackbar(WorkerMovementsEditForm);
