import React from 'react';
import {Dialog as MDialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button";

const Dialog = ({ open = true, title, onAccept, onClose, acceptLabel = "Confirmar", children = null }) => {

    return <MDialog
        open={open}
        onClose={onClose}
    >
        <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
        <DialogContent>
            { children }
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Cancelar
            </Button>
            <Button onClick={onAccept} color="primary" autoFocus>
                { acceptLabel }
            </Button>
        </DialogActions>
    </MDialog>
};

export default Dialog;
