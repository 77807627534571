import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {withSnackbar} from "notistack";
import Container from "@material-ui/core/Container";
import {COLOR_PRIMARY, COLOR_SECONDARY} from "../../../config/colors";
import {useSelector} from "react-redux";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MoneyIcon from '@material-ui/icons/Money';
import WorkersSearchField from "../../../components/Fields/WorkersSearchField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import axios from "axios";
import {API_URL} from "../../../config";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import moment from "moment";
import MaterialTable from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30,
        display: 'flex',
        flexDirection: 'column'
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        marginTop: 20,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    id: {
        color: COLOR_SECONDARY,
        fontWeight: 'bold'
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    filterMenuWrapper: {
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 10,
        backgroundColor: '#FAFAFA'
    },
    filterMenuHeader: {
        marginLeft: 10,
        marginRight: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    chip: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: COLOR_PRIMARY,
        borderRadius: 33,
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 8,
        paddingLeft: 8,
    }
}));

const PaymentsSearch = ({ history, enqueueSnackbar }) => {

    const classes = useStyles();

    const authState = useSelector((state) => state.authReducer);

    const [worker, setWorker] = useState(null);
    const [quarter, setQuarter  ] = useState(null);
    const [payment, setPayment  ] = useState(null);

    const [quarters, setQuarters] = useState([]);
    const [workerPayments, setWorkerPayments] = useState([]);

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);

    const [missingPayment, setMissingPayment] = useState(true);

    const formFields = {
        worker_id: null,
    };

    const tableRef = useRef(null);

    const handleWorkerChange = async (event, value) => {
        setWorker(value);

        await loadWorkerQuarters(value.id);

    };

    useEffect(() => {
        const init = async () => {
            await loadQuarters();
        };

        init();
    }, []);

    const loadQuarters = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/quarters`
            );

            // Actualizar state
            setQuarters(response.data);

        } catch(error) {

            enqueueSnackbar('Ocurrió un error cargando los trimestres.');

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const loadWorkerQuarters = async (id) => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/workers/${id}/payments`
            );

            setWorkerPayments(response.data);

        } catch(error) {

            enqueueSnackbar('Ocurrió un error cargando los trimestres del trabajador.');

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const onQuarterClick = async (id) => {

        setMissingPayment(true);

        setQuarter(id);

        let quarterHasPayment = workerPayments.find((payment) => (payment.quarter_id === id)) !== undefined;

        if(quarterHasPayment){
            setMissingPayment(false);

           setPayment(workerPayments.find((payment) => (payment.quarter_id === id)));
        }

    };

    const renderQuarterStatus = (id) => {
        if(worker === null)
            return '---';

        let quarterHasPayment = workerPayments.find((payment) => (payment.quarter_id === id)) !== undefined;

        if(quarterHasPayment)
            return 'PAGADO';
        else
            return 'PENDIENTE';
    };

    const renderQuarters = () => {
        return quarters.map((qua) => {
            return <ListItem button disabled={worker === null} onClick={() => onQuarterClick(qua.id)} selected={quarter !== null && qua.id === quarter}>
                <ListItemIcon>
                    <CalendarTodayIcon />
                </ListItemIcon>
                <ListItemText primary={qua.description} secondary={ renderQuarterStatus(qua.id) } />
            </ListItem>
        });
    };

    const renderFees = () => {
        return payment.fees.map((fee) => (
            <TableRow>
                <TableCell component="th" scope="row">
                    {fee.name}
                </TableCell>
                <TableCell>${fee.pivot.payment_amount.toFixed(2)}</TableCell>
            </TableRow>
        ));
    };

    return (
        <>
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    <Paper style={{padding: 20}}>
                        <WorkersSearchField initialValues={formFields} onWorkerSelect={handleWorkerChange} />
                    </Paper>
                    <Grid container spacing={3} style={{marginTop: 10, flex: 1}}>
                        <Grid item sm={3} xs={12} style={{display: 'flex', flexDirection: 'column'}}>
                            <Paper style={{padding: 8, display: 'flex', flexDirection: 'column', flex: 1, maxHeight: 350}}>
                                <List style={{maxHeight: 350, overflow: 'scroll'}}>
                                    { renderQuarters() }
                                </List>
                            </Paper>
                        </Grid>
                        {
                            worker !== null ?
                                quarter !== null ?
                                    missingPayment === false ?
                                        <Grid item sm={9} xs={12}>
                                            <Paper style={{padding: 16}}>
                                                <div style={{display: 'flex', flexDirection: 'row', marginBottom: 16}}>
                                                    <div className={classes.chip} style={{marginRight: 16}}>
                                                        <MoneyIcon color="primary" style={{marginRight: 8}} />
                                                        <Typography variant="body2" style={{color: COLOR_PRIMARY}}>${payment.total.toFixed(2)}</Typography>
                                                    </div>
                                                    <div className={classes.chip}>
                                                        <CalendarTodayIcon color="primary" style={{marginRight: 8}} />
                                                        <Typography variant="body2" style={{color: COLOR_PRIMARY}}>{moment(payment.paid_at).format('DD MMM YYYY')}</Typography>
                                                    </div>
                                                </div>
                                                <TableContainer>
                                                    <MaterialTable className={classes.table}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Concepto</TableCell>
                                                                <TableCell>Monto</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                renderFees()
                                                            }
                                                        </TableBody>
                                                    </MaterialTable>
                                                </TableContainer>
                                            </Paper>
                                        </Grid>:
                                        <Grid item sm={9} xs={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                            <Typography align="center" variant="h5">No se ha registrado pago para el periodo seleccionado.</Typography>
                                            <Typography align="center" variant="body1" style={{color: '#333'}}>Da click en el botón a continuación para capturar el pago.</Typography>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                style={{marginTop: 30}}
                                                onClick={() => history.push(`/workers/${worker.id}/payments/${quarter}/create`)}
                                            >
                                                Capturar Pago
                                            </Button>
                                        </Grid> :
                                    <Grid item sm={9} xs={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                        <Typography align="center" variant="h5">Seleccione un Periodo.</Typography>
                                        <Typography align="center" variant="body1" style={{color: '#333'}}>Seleccione un periodo para mostrar la información del pago del trabajador.</Typography>
                                    </Grid> :
                                <Grid item sm={9} xs={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <Typography align="center" variant="h5">Seleccione un Trabajador.</Typography>
                                    <Typography align="center" variant="body1" style={{color: '#333'}}>Busque y seleccione un trabajador para mostrar sus pagos.</Typography>
                                </Grid>
                        }
                    </Grid>
                </div>
            </Container>
        </>
    );

};

export default withSnackbar(PaymentsSearch);
