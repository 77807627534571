import React, {useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as moment from 'moment';
import axios from 'axios';
import {API_URL} from "../../../config";
import Dialog from "../../../components/Dialog/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import Table from "../../../components/Table/Table";
import {generateApiUrl, getDeactivatedMessage} from "../../../helpers/helpers";
import {withSnackbar} from "notistack";
import Container from "@material-ui/core/Container";
import {COLOR_SECONDARY} from "../../../config/colors";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import UITable from '@material-ui/core/Table';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    id: {
        color: COLOR_SECONDARY,
        fontWeight: 'bold'
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

const CompaniesIndex = ({ history, enqueueSnackbar }) => {

    const classes = useStyles();

    // State de tabla
    const [selected, setSelected] = useState([]);

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);

    // State de diálogo
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    let [columnObject] = useState([
        {
            title: 'ID',
            field: 'id',
            filtering: false,
            render: rowData => <Typography className={classes.id}>{rowData.id}</Typography>
        },
        { title: 'Nombre', field: 'name' },
        {
            title: 'Dirección',
            field: 'street_address',
            filtering: false,
            render: rowData => `${rowData.street_address ? rowData.street_address : ''} ${rowData.suburb ? rowData.suburb : ''}`
        },
        {
            title: 'Fecha Inicio',
            field: 'start_at',
            filtering: false,
            render: rowData => {
                return rowData.start_at && moment(rowData.start_at).format('D MMM YYYY');
            }
        },
        {
            title: 'Fecha Fin',
            field: 'end_at',
            filtering: false,
            render: rowData => {
                return rowData.end_at && moment(rowData.end_at).format('D MMM YYYY');
            }
        },
        {
            title: 'Estado',
            field: 'is_active',
            type: 'boolean',
            defaultFilter: 'checked'
        },
    ]);

    const tableRef = useRef(null);

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handleDeactivate = async () => {

        // Esconder el diálogo
        setIsDialogOpen(false);

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = selected.length;

            // Contadores para mensaje
            let deactivatedCount = 0;
            let activatedCount = 0;

            // Por cada categoría seleccionada
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${API_URL}/companies/${selected[i].id}`);

                // Actualizar contador
                if(selected[i].is_active)
                    deactivatedCount += 1;
                else
                    activatedCount += 1;
            }

            // Conseguir el mensaje
            const resultMessage = getDeactivatedMessage(deactivatedCount, activatedCount);

            // Mostrar mensaje
            enqueueSnackbar(resultMessage);

            // Vaciar selección
            setSelected([]);

            // Cargar tabla
            tableRef.current.onQueryChange();

        } catch (error) {

            if(error.response.data.message)
            // Mostrar mensaje
                enqueueSnackbar(error.response.data.message);
            else
            // Mostrar mensaje
                enqueueSnackbar('Ocurrió un error activando o desactivando los registros.');
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    // Función que define los filtros y carga los registros
    const loadTableData = (query) => {

        return new Promise(async (resolve, reject) => {

            // Mostrar indicador de carga
            setIsLoading(true);

            // Generar el url
            const url = generateApiUrl(`${API_URL}/companies`, query);

            try {

                // Realizar request
                const response = await axios.get(url + '&with_delegates=true');

                console.log(response.data.data);

                resolve({data: response.data.data, page: response.data.current_page - 1, totalCount: response.data.total});

            } catch(error){
                reject(new Error('Ocurrió un error cargando los registros.'))
            }

            // Esconder indicador de carga
            setIsLoading(false);

        }).catch((error) => {

            // Mostrar mensaje
            enqueueSnackbar('Ocurrió un error cargando los registros.');

        });
    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    <Table
                        title="Empresas"
                        tableRef={tableRef}
                        loading={isLoading}
                        columns={columnObject}
                        loadTableData={(query) => loadTableData(query)}
                        selected={selected}
                        onSelect={(rows) => setSelected(rows)}
                        onCreate={() => history.push('/companies/create')}
                        onEdit={(id) => history.push(`/companies/${id}/edit`)}
                        onDestroy={() => setIsDialogOpen(true)}
                        detailPanel={rowData => (
                            <div style={{backgroundColor: '#FAFAFA'}}>
                                <TableContainer>
                                    <UITable className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Puesto</TableCell>
                                                <TableCell>CURP</TableCell>
                                                <TableCell>Nombre(s)</TableCell>
                                                <TableCell>Apellido Paterno</TableCell>
                                                <TableCell>Apellido Materno</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                rowData.workers.map(worker => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                {worker.pivot.job_position}
                                                            </TableCell>
                                                            <TableCell>{worker.curp}</TableCell>
                                                            <TableCell>{worker.first_name}</TableCell>
                                                            <TableCell>{worker.fathers_last_name}</TableCell>
                                                            <TableCell>{worker.mothers_last_name ? worker.mothers_last_name : ''}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </UITable>
                                </TableContainer>
                            </div>
                        )}
                    />
                    <Dialog
                        open={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        onAccept={ handleDeactivate }
                        title="Desactivar o activar registros"
                    >
                        <DialogContentText id="alert-dialog-description">
                            ¿Está seguro que desea desactivar o activar los registros?
                        </DialogContentText>
                    </Dialog>
                </div>
            </Container>
        </>
    );

};

export default withSnackbar(CompaniesIndex);
