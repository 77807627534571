import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import axios from 'axios';
import {API_URL} from "../../../config";
import Dialog from "../../../components/Dialog/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import Table from "../../../components/Table/Table";
import {generateApiUrl} from "../../../helpers/helpers";
import {withSnackbar} from "notistack";
import Container from "@material-ui/core/Container";
import {COLOR_SECONDARY} from "../../../config/colors";
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterMenu from "../../../components/FilterMenu/FilterMenu";
import {useSelector} from "react-redux";
import moment from "moment";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import WarningIcon from '@material-ui/icons/Warning';
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import UITable from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import {NavLink} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    id: {
        color: COLOR_SECONDARY,
        fontWeight: 'bold'
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    filterMenuWrapper: {
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 10,
        backgroundColor: '#FAFAFA'
    },
    filterMenuHeader: {
        marginLeft: 10,
        marginRight: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    }
}));

const FeesDetailPanel = ({ rowData }) => {

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);

    const [error, setError] = useState('');

    useEffect(() => {
        const init = async () => {
            // await loadWorkerFees();
        };

        init();
    }, []);

    return <div style={{backgroundColor: '#FAFAFA'}}>
        {
            isLoading ?
                <div className={classes.inlineProgress} style={{paddingTop: 20, paddingBottom: 20}}>
                    <CircularProgress/>
                </div> :
                error !== '' ?
                    <div className={classes.errorWrapper} style={{paddingTop: 20, paddingBottom: 20}}>
                        <WarningIcon color="primary" style={{marginRight: 8}}/>
                        <Typography>{error}</Typography>
                    </div> :
                    <TableContainer>
                        <UITable className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Cuota</TableCell>
                                    <TableCell>Costo</TableCell>
                                    <TableCell>Fecha</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            </TableBody>
                        </UITable>
                    </TableContainer>
        }
    </div>
};

const PaymentsIndex = ({ history, enqueueSnackbar }) => {

    const classes = useStyles();

    const authState = useSelector((state) => state.authReducer);

    // State de tabla
    const [selected, setSelected] = useState([]);

    const [filterAnchor, setFilterAnchor] = useState(null);

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);

    // State de diálogo
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    let [columnObject] = useState([
        {
            title: 'Trabajador',
            field: 'first_name',
            render: rowData => {
                return <NavLink to={`/workers/${rowData.worker_id}?referrer=payments`}><Typography
                    className={classes.id}>{rowData.first_name} {rowData.fathers_last_name} {rowData.mothers_last_name ? rowData.mothers_last_name : ''}</Typography></NavLink>;
            }
        },
        {
            title: 'Trimestre',
            field: 'quarter'
        },
        {
            title: 'Pago',
            field: 'total',
            filtering: false,
            render: rowData => {
                return `$${rowData.total.toFixed(2)}`;
            }
        },
        {
            title: 'Caja',
            field: 'checkout_id',
            render: rowData => {
                return `Caja ${rowData.checkout_id}`;
            }
        },
        {
            title: 'Pagado',
            field: 'paid_at',
            filtering: false,
            render: rowData => {
                return moment(rowData.paid_at).format('D MMM YYYY');
            }
        },
        {
            title: 'Estado',
            field: 'is_active',
            type: 'boolean',
            defaultFilter: 'checked'
        },
    ]);

    const defaultFiltersData = [
        {
            field: {
                column: 'paid_at',
                table: 'payments',
                label: 'Fecha de Pago'
            },
            value: [null, null],
            control: {
                open: false,
                type: 'date',
            }
        }
    ];

    const [filtersData, setFiltersData] = useState(defaultFiltersData);

    const tableRef = useRef(null);

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handleDelete = async () => {

        // Esconder el diálogo
        setIsDialogOpen(false);

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = selected.length;

            // Contadores para mensaje
            let deletedCount = 0;

            // Por cada categoría seleccionada
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${API_URL}/payments/${selected[i].id}`);

                deletedCount += 1;
            }

            // Conseguir el mensaje
            const resultMessage = `${deletedCount} registros eliminados`;

            // Mostrar mensaje
            enqueueSnackbar(resultMessage);

            // Vaciar selección
            setSelected([]);

            // Cargar tabla
            tableRef.current.onQueryChange();

        } catch (error) {

            if(error.response.data.message)
            // Mostrar mensaje
                enqueueSnackbar(error.response.data.message);
            else
            // Mostrar mensaje
                enqueueSnackbar('Ocurrió un error eliminando los registros.');
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    // Función que define los filtros y carga los registros
    const loadTableData = (query) => {

        return new Promise(async (resolve, reject) => {

            // Mostrar indicador de carga
            setIsLoading(true);

            // Generar el url
            const url = generateApiUrl(`${API_URL}/payments`, query, filtersData);

            try {

                // Realizar request
                const response = await axios.get(url);

                resolve({data: response.data.data, page: response.data.current_page - 1, totalCount: response.data.total});

            } catch(error){
                reject(new Error('Ocurrió un error cargando los registros.'))
            }

            // Esconder indicador de carga
            setIsLoading(false);

        }).catch((error) => {

            // Mostrar mensaje
            enqueueSnackbar('Ocurrió un error cargando los registros.');

        });
    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    <Table
                        title="Pagos"
                        tableRef={tableRef}
                        loading={isLoading}
                        columns={columnObject}
                        loadTableData={(query) => loadTableData(query)}
                        selected={selected}
                        onSelect={(rows) => setSelected(rows)}
                        onDestroy={() => setIsDialogOpen(true)}
                        canDelete={authState.roleId === 1}
                        canEdit={false}
                        canCreate={false}
                        detailPanel={rowData => <FeesDetailPanel rowData={rowData} />}
                        customActions={[
                            {
                                icon: FilterListIcon,
                                tooltip: 'Filtrar',
                                isFreeAction: true,
                                onClick: async (event) => {
                                    setFilterAnchor(event.currentTarget);
                                }
                            }
                        ]}
                    />
                    <Dialog
                        open={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        onAccept={ handleDelete }
                        title="Eliminar registro(s)"
                    >
                        <DialogContentText id="alert-dialog-description">
                            ¿Está seguro que desea eliminar los registros? Esta acción es permanente.
                        </DialogContentText>
                    </Dialog>
                    <FilterMenu
                        filtersConfig={defaultFiltersData}
                        filterAnchor={filterAnchor}
                        onClose={() => setFilterAnchor(null)}
                        onSave={(data) => {
                            setFiltersData(data);
                            tableRef.current.onChangePage(null, 0);
                            setFilterAnchor(null);
                        }}
                        onClear={() => setFiltersData(defaultFiltersData)}
                    />
                </div>
            </Container>
        </>
    );

};

export default withSnackbar(PaymentsIndex);
