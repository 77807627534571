import React, {useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as moment from 'moment';
import axios from 'axios';
import {API_URL} from "../../../config";
import Dialog from "../../../components/Dialog/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import Table from "../../../components/Table/Table";
import {generateApiUrl, getDeactivatedMessage} from "../../../helpers/helpers";
import {withSnackbar} from "notistack";
import Container from "@material-ui/core/Container";
import {COLOR_SECONDARY} from "../../../config/colors";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {SaveAlt} from "@material-ui/icons";
import fileDownload from 'js-file-download';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    id: {
        color: COLOR_SECONDARY,
        fontWeight: 'bold'
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

const UsersIndex = ({ history, enqueueSnackbar }) => {

    const classes = useStyles();

    const [roles, setRoles] = useState();

    // State de tabla
    const [selected, setSelected] = useState([]);

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);
    const [isInitLoading, setIsInitLoading] = useState(true);

    // State de diálogo
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    let columnObject = useMemo(() => ([
        {
            title: 'ID',
            field: 'id',
            filtering: false,
            render: rowData => <Typography className={classes.id}>{rowData.id}</Typography>
        },
        { title: 'Nombre', field: 'name' },
        { title: 'Usuario', field: 'username' },
        {
            title: 'Rol',
            field: 'role_id',
            lookup: roles
        },
        {
            title: 'Creado',
            field: 'created_at',
            filtering: false,
            render: rowData => {
                return moment(rowData.created_at).format('D MMM YYYY');
            }
        },
        {
            title: 'Estado',
            field: 'is_active',
            type: 'boolean',
            defaultFilter: 'checked'
        },
    ]), roles);

    const tableRef = useRef(null);

    useEffect(() => {

        const init = async () => {
            await loadRoles();
        };

        init();
    }, []);

    // Función que carga los roles
    const loadRoles = async () => {

        // Mostrar indicador de carga
        setIsInitLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/roles`,
                {
                    params: {
                        sort: 'name',
                        direction: 'asc',
                        active: true
                    }
                }
            );

            let newRoles = {};

            for(let i = 0; i < response.data.length; i++){
                newRoles[response.data[i].id] = response.data[i].name;
            }

            // Actualizar state
            setRoles(newRoles);

        } catch(error) {

            enqueueSnackbar('Ocurrió un error cargando los roles.');

        }

        // Esconder indicador de carga
        setIsInitLoading(false);
    };

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handleDeactivate = async () => {

        // Esconder el diálogo
        setIsDialogOpen(false);

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = selected.length;

            // Contadores para mensaje
            let deactivatedCount = 0;
            let activatedCount = 0;

            // Por cada categoría seleccionada
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${API_URL}/users/${selected[i].id}`);

                // Actualizar contador
                if(selected[i].is_active)
                    deactivatedCount += 1;
                else
                    activatedCount += 1;
            }

            // Conseguir el mensaje
            const resultMessage = getDeactivatedMessage(deactivatedCount, activatedCount);

            // Mostrar mensaje
            enqueueSnackbar(resultMessage);

            // Vaciar selección
            setSelected([]);

            // Cargar tabla
            tableRef.current.onQueryChange();

        } catch (error) {

            if(error.response.data.message)
            // Mostrar mensaje
                enqueueSnackbar(error.response.data.message);
            else
                // Mostrar mensaje
                enqueueSnackbar('Ocurrió un error activando o desactivando los registros.');
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    // Función que define los filtros y carga los registros
    const loadTableData = (query) => {

        return new Promise(async (resolve, reject) => {

            // Mostrar indicador de carga
            setIsLoading(true);

            // Generar el url
            const url = generateApiUrl(`${API_URL}/users`, query);

            try {

                // Realizar request
                const response = await axios.get(url);

                resolve({data: response.data.data, page: response.data.current_page - 1, totalCount: response.data.total});

            } catch(error){
                reject(new Error('Ocurrió un error cargando los registros.'))
            }

            // Esconder indicador de carga
            setIsLoading(false);

        }).catch((error) => {

            // Mostrar mensaje
            enqueueSnackbar('Ocurrió un error cargando los registros.');

        });
    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isInitLoading ?
                            <div className={classes.progress}>
                                <CircularProgress/>
                            </div> :
                            <Table
                                title="Usuarios"
                                tableRef={tableRef}
                                loading={isLoading}
                                columns={columnObject}
                                loadTableData={(query) => loadTableData(query)}
                                selected={selected}
                                onSelect={(rows) => setSelected(rows)}
                                onCreate={() => history.push('/users/create')}
                                onEdit={(id) => history.push(`/users/${id}/edit`)}
                                onDestroy={() => setIsDialogOpen(true)}
                            />
                    }
                    <Dialog
                        open={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        onAccept={ handleDeactivate }
                        title="Desactivar o activar registros"
                    >
                        <DialogContentText id="alert-dialog-description">
                            ¿Está seguro que desea desactivar o activar los registros?
                        </DialogContentText>
                    </Dialog>
                </div>
            </Container>
        </>
    );

};

export default withSnackbar(UsersIndex);
