
import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const Loading = () => {

    const classes = useStyles();

    document.body.style.backgroundColor = '#fafafa';

    return <div className={ classes.progress }>
        <CircularProgress />
    </div>
};

export default Loading;
