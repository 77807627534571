
export const generateApiUrl = (baseUrl, query, filtersData = null) => {

    // Generar URL base y agregar opciones de paginación
    let url = baseUrl + '?';
    url += 'per_page=' + query.pageSize;
    url += '&page=' + (query.page + 1);

    // Si se seleccionaron opciones de orden, agregar a URL
    if(query.orderBy && query.orderDirection){
        url += '&sort=' + query.orderBy.field;
        url += '&direction=' + query.orderDirection;
    }

    // Si se seleccionaron filtros, agregar a URL
    if(query.filters){
        for(let i = 0; i < query.filters.length; i++){
            if(query.filters[i].column.field === 'is_active' || query.filters[i].column.field === 'is_default')
                url += `&${query.filters[i].column.field}=${query.filters[i].value === 'checked' ? 1 : 0}`;
            else
                url += `&${query.filters[i].column.field}=${query.filters[i].value}`;
        }
    }

    if(filtersData){
        for(let i = 0; i < filtersData.length; i++){
            if(filtersData[i].value){
                switch(filtersData[i].control.type){
                    case 'date':
                        if(filtersData[i].value[0] !== null || filtersData[i].value[1] !== null){
                            url += `&${filtersData[i].field.column}=true`;
                            if(filtersData[i].value[0])
                                url += `&${filtersData[i].field.column}_start=${filtersData[i].value[0].format('YYYY-MM-DD')}`;
                            if(filtersData[i].value[1])
                                url += `&${filtersData[i].field.column}_end=${filtersData[i].value[1].format('YYYY-MM-DD')}`;
                        }
                        break;
                    case 'text':
                        url += `&${filtersData[i].field.column}=${filtersData[i].value}`;
                        break;
                    case 'select':
                        url += `&${filtersData[i].field.column}=${filtersData[i].value}`;
                        break;
                }
            }
        }
    }

    // Si se llenó el campo de búsqueda, agregar a filtros
    if(query.search)
        url += '&search=' + query.search;

    return url;
};

export const getDeactivatedMessage = (deactivatedCount, activatedCount) => {

    if(deactivatedCount > 1 && activatedCount > 1){
        return`${deactivatedCount} registros desactivados y ${activatedCount} registros activados.`;
    } else if(deactivatedCount === 1 && activatedCount === 1){
        return `1 registro desactivado y 1 registro activado.`;
    } else if(deactivatedCount > 1 && activatedCount === 0){
        return `${deactivatedCount} registros desactivados.`;
    } else if(deactivatedCount === 1 && activatedCount === 0){
        return `1 registro desactivado.`;
    } else if(deactivatedCount === 0 && activatedCount > 1){
        return `${activatedCount} registros activados.`;
    } else if(deactivatedCount === 0 && activatedCount === 1){
        return `1 registro activado.`;
    } else if(deactivatedCount === 1 && activatedCount > 1){
        return `1 registro desactivado y ${activatedCount} registros activados.`;
    } else {
        return `${deactivatedCount} registros desactivados y 1 registro activado.`;
    }

};

export const defaultStringify = (value) => {
    if (value == null) {
        return '';
    }

    if (typeof value === 'string') {
        return value;
    }

    if (typeof value === 'object') {
        return Object.keys(value).map(key => value[key]).join(' ');
    }

    return JSON.stringify(value);
};

export const generateFilterObject = (query, customFilters) => {

    let data = {};

    data.filters = query.filters.map((filter) => {
        return {field: filter.column.field, value: filter.value}
    });

    data.filters = [
        ...data.filters,
        ...customFilters.map((filter) => {
            if(Array.isArray(filter.value)){
                if(filter.value[0] !== null || filter.value[1] !== null){
                    return {field: filter.field.column, value: filter.value, type: filter.control.type, table: filter.field.table}
                }
            } else if(filter.value){
                return {field: filter.field.column, value: filter.value, type: filter.control.type, table: filter.field.table}
            }
        })
        .filter((filter) => filter !== undefined)
    ];

    data.search = query.search;

    data.orderBy = query.orderBy && query.orderBy.field;
    data.orderDirection = query.orderDirection;

    return data;
};

export const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
};

export const checkIfArrayIsUnique = myArray => {

    if(!myArray){
        return true;
    }

    let newArray = myArray.map(val => val.id);

    return newArray.length === new Set(newArray).size;
};
