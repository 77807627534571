import React, {useState} from 'react';
import {Form} from "react-final-form";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import validate from "validate.js";
import axios from "axios";
import {API_URL} from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {withSnackbar} from "notistack";
import { throttle } from "throttle-debounce";
import {createFilterOptions} from "@material-ui/lab";
import {defaultStringify, toInputUppercase} from "../../helpers/helpers";
import {KeyboardDatePicker, TextField, Autocomplete} from "mui-rff";
import Dialog from "../Dialog/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import createDecorator from "final-form-focus";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    subtitle: {
        marginTop: theme.spacing(2)
    }
}));

validate.validators.customDate = function(value, options, key, attributes) {
    return moment.isMoment(value) && value.isValid() ? null : "Debe de ingresar una fecha válida";
};

const WorkerMovementsCreateForm = ({ initialValues, onSuccess, onError, onLoadingChange, submitting, enqueueSnackbar, history }) => {

    const classes = useStyles();

    const focusOnErrors = createDecorator();

    const [workers, setWorkers] = useState([]);
    const [companies, setCompanies] = useState([]);

    const [workersSearching, setWorkersSearching] = useState(false);
    const [companiesSearching, setCompaniesSearching] = useState(false);

    const [currentCompanyId, setCurrentCompanyId] = useState('');

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const filterOptions = createFilterOptions({
        ignoreAccents: true,
        ignoreCase: true,
        stringify: defaultStringify,
        trim: true
    });

    const handleWorkerInputChange = async (event, value, reason) => {

        if(reason === 'input' && value.length > 3){

            await loadWorkers(value);
        }

    };

    const handleCompaniesInputChange = async (event, value, reason) => {

        if(reason === 'input' && value.length > 2){
            await loadCompanies(value);
        }

    };

    const workerSearchThrottled = throttle(1000, handleWorkerInputChange);
    const companySearchThrottled = throttle(1000, handleCompaniesInputChange);

    // Reglas de validación de formulario
    const constraints = {
        worker_id: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        company_id: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        job_position: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        location: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        shift: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        start_at: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            },
            customDate: {
                message: "Debe de ingresar una fecha válida"
            }
        },
    };

    const loadWorkers = async (term) => {

        setWorkersSearching(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/workers`,
                {
                    params: {
                        sort: 'curp',
                        direction: 'asc',
                        active: true,
                        search: term
                    }
                }
            );

            setWorkers(response.data);

        } catch(error) {

            enqueueSnackbar('Ocurrió un error cargando los trabajadores.');

        }

        setWorkersSearching(false);
    };

    const loadCompanies = async (term) => {

        setCompaniesSearching(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/companies`,
                {
                    params: {
                        sort: 'name',
                        direction: 'asc',
                        active: true,
                        search: term
                    }
                }
            );

            setCompanies(response.data);

        } catch(error) {

            enqueueSnackbar('Ocurrió un error cargando las empresas.');

        }

        setCompaniesSearching(false);
    };

    // Función que se ejecuta al enviar formulario
    async function onSubmit(values, form) {

        // Mostrar indicador de carga
        onLoadingChange(true);

        setCurrentCompanyId('');

        try {

            // Hacer request
            const response = await axios.get(`${API_URL}/workers/${values.worker_id}`);

            if(response.data.worker.company){

                setCurrentCompanyId(response.data.worker.company.pivot.id);

                setIsDialogOpen(true);

                onLoadingChange(false);

                return;
            }

            // Datos a enviar
            const data = { ...values };

            data.start_at = data.start_at.format('YYYY-MM-DD');

            // Enviar datos a API
            await axios.post(
                `${API_URL}/worker_movements`,
                data
            );

            setTimeout(form.restart);

            // Enviar mensaje de éxito a componente padre
            onSuccess('Movimiento creado.');

            // Esconder indicador de carga
            onLoadingChange(false);

        } catch (error) {

            // Esconder indicador de carga
            onLoadingChange(false);

            // Dependiendo del error, mostrar mensajes
            switch(error.response.status){
                case 400:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                case 422:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                default:
                    // Mostrar mensaje
                    onError("Ocurrió un error desconocido.");
                    break;
            }

        }

    }

    // Función que valida el formulario
    async function validateForm(values) {

        // Validar campos
        let valid = validate({ ...values }, constraints, {fullMessages: false});

        // Si no hay error
        if(!valid)
            return;
        else {

            // Modificar objeto para mostrar errores en formulario
            Object.keys(valid).forEach((key, index) => {
                valid[key] = valid[key][0];
            });

            return valid;
        }
    }

    // Función que manipula objeto para mostrar errores
    const handleServerError = (error) => {

        Object.keys(error).forEach((key, index) => {
            error[key] = error[key][0];
        });

        return error;
    };

    const handleDialogAccept = () => {

        setIsDialogOpen(false);

        history.push(`/worker_movements/${currentCompanyId}/edit`);
    };

    return <>
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            decorators={[ focusOnErrors ]}
            validate={validateForm}
            render={({ handleSubmit, values, submitError, form }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item sm={4} xs={12}>
                            <Autocomplete
                                name="worker_id"
                                label="Trabajador"
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => `${option.first_name} ${option.fathers_last_name} ${option.mothers_last_name ? option.mothers_last_name : ''}`}
                                options={workers}
                                loading={workersSearching}
                                filterOptions={filterOptions}
                                onInputChange={workerSearchThrottled}
                                noOptionsText="No hay resultados"
                                loadingText="Cargando..."
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Autocomplete
                                name="company_id"
                                label="Empresa"
                                getOptionValue={(option) => option.id }
                                getOptionLabel={(option) => `${option.name}`}
                                options={companies}
                                loading={companiesSearching}
                                filterOptions={filterOptions}
                                onInputChange={companySearchThrottled}
                                noOptionsText="No hay resultados"
                                loadingText="Cargando..."
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <KeyboardDatePicker label="Fecha de Ingreso*" name="start_at" disableFuture={true} format={'DD-MM-YYYY'} disableToolbar={false}/>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Puesto*" name="job_position" inputProps={{onInput: toInputUppercase}}/>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Planta*" name="location" inputProps={{onInput: toInputUppercase}}/>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Turno*" name="shift" inputProps={{onInput: toInputUppercase}}/>
                        </Grid>
                    </Grid>
                    <div className={ classes.buttonWrapper }>
                        <Button color="secondary" type="submit" disabled={submitting} startIcon={ submitting ? <CircularProgress size={18}/> : null }>Crear</Button>
                    </div>
                </form>
            )}
        />
        <Dialog
            title={'Alerta'}
            open={isDialogOpen}
            acceptLabel="Ver movimiento"
            onClose={() => setIsDialogOpen(false)}
            onAccept={ handleDialogAccept }>
            <DialogContentText id="alert-dialog-description">
                El trabajador seleccionado no ha sido dado de baja de la empresa en la que se encuentra actualmente. Antes de asignarlo a una nueva empresa, debe de darlo de baja.
            </DialogContentText>
        </Dialog>
    </>;
};

export default withSnackbar(WorkerMovementsCreateForm);
