import React, {useEffect, useRef, useState} from 'react';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import WarningIcon from '@material-ui/icons/Warning';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {NavLink} from "react-router-dom";
import {withSnackbar} from "notistack";
import axios from 'axios';
import TableContainer from "@material-ui/core/TableContainer";
import MaterialTable from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {API_URL, APP_URL} from "../../../config";
import moment from 'moment';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import FilterListIcon from '@material-ui/icons/FilterList';
import EmailIcon from '@material-ui/icons/Email';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import {generateApiUrl, getDeactivatedMessage} from "../../../helpers/helpers";
import Table from "../../../components/Table/Table";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "../../../components/Dialog/Dialog";
import {useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import QRCode from 'qrcode';
import JsBarcode from "jsbarcode";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Credentials from "../../../components/Documents/Credentials";
import MUIDialog from "@material-ui/core/Dialog/Dialog";
import fileDownload from "js-file-download";
import {STATUS_TYPES} from "../../../config/general";
import {COLOR_SECONDARY} from "../../../config/colors";
import UITable from "@material-ui/core/Table/Table";
import FilterMenu from "../../../components/FilterMenu/FilterMenu";
import * as queryString from "query-string";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    inlineProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2)
    },
    title: {
        flex: '1 1 100%',
    },
    backButton: {
        marginRight: 8
    },
    gallery: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
    },
    image: {
        height: 200,
        margin: 2
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    iconError: {
        color: theme.palette.error.main
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        width: theme.spacing(16),
        height: theme.spacing(16),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

const FeesDetailPanel = ({ rowData }) => {

    const classes = useStyles();

    const [fees, setFees] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [error, setError] = useState('');

    useEffect(() => {

        const init = async () => {
            await loadFees();
        };

        init();
    }, []);

    const loadFees = async () => {
        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/payments/${rowData.id}`);

            setFees(response.data.fees);

        } catch(error){

        }

        setIsLoading(false);
    };

    const renderFees = () => {
        return fees.map(fee => (
            <TableRow>
                <TableCell>{fee.name}</TableCell>
                <TableCell>{`$${fee.price}`}</TableCell>
            </TableRow>
        ));
    };

    return <div style={{backgroundColor: '#FAFAFA'}}>
        {
            isLoading ?
                <div className={classes.inlineProgress} style={{paddingTop: 20, paddingBottom: 20}}>
                    <CircularProgress/>
                </div> :
                error !== '' ?
                    <div className={classes.errorWrapper} style={{paddingTop: 20, paddingBottom: 20}}>
                        <WarningIcon color="primary" style={{marginRight: 8}}/>
                        <Typography>{error}</Typography>
                    </div> :
                    <TableContainer>
                        <UITable className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Cuota</TableCell>
                                    <TableCell>Costo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    renderFees()
                                }
                            </TableBody>
                        </UITable>
                    </TableContainer>
        }
    </div>
};

const WorkersView = ({ history, match, location, enqueueSnackbar }) => {

    const classes = useStyles();

    const authState = useSelector((state) => state.authReducer);

    let statusLookup = () => {

        let lookup = {};

        for(let i = 0; i < STATUS_TYPES.length; i++){
            lookup[STATUS_TYPES[i]] = STATUS_TYPES[i];
        }

        return lookup;
    };

    const [worker, setWorker] = useState(null);

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);
    const [isMovementsLoading, setIsMovementsLoading] = useState(true);
    const [isPaymentsLoading, setIsPaymentsLoading] = useState(true);

    // State de diálogo
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isFeeDialogOpen, setIsFeeDialogOpen] = React.useState(false);
    const [isPaymentsDialogOpen, setIsPaymentsDialogOpen] = React.useState(false);
    const [isStatusDialogOpen, setIsStatusDialogOpen] = React.useState(false);
    const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);

    // State de tabla
    const [selected, setSelected] = useState([]);
    const [paymentsSelected, setPaymentsSelected] = useState([]);

    const [error, setError] = useState('');

    // ID del documento
    const id = match.params.id;

    const [actionsAnchor, setActionsAnchor] = React.useState(null);
    const [paymentsFilterAnchor, setPaymentsFilterAnchor] = useState(null);

    let [movementsColumnObject] = useState([
        { title: 'Empresa', field: 'name' },
        { title: 'Puesto', field: 'job_position' },
        {
            title: 'Fecha de Inicio',
            field: 'start_at',
            filtering: false,
            render: rowData => {
                return moment(rowData.start_at).format('D MMM YYYY');
            }
        },
        {
            title: 'Fecha de Fin',
            field: 'end_at',
            filtering: false,
            defaultSort: 'desc',
            render: rowData => {
                return rowData.end_at && moment(rowData.end_at).format('D MMM YYYY');
            }
        }
    ]);

    let [paymentsColumnObject] = useState([
        {
            title: 'Pago',
            field: 'total',
            filtering: false,
            render: rowData => {
                return `$${rowData.total}`;
            }
        },
        {
            title: 'Notas',
            field: 'notes',
            filtering: false,
        },
        {
            title: 'Pagado',
            field: 'paid_at',
            filtering: false,
            render: rowData => {
                return moment(rowData.paid_at).format('D MMM YYYY');
            }
        }
    ]);

    const defaultPaymentsFiltersData = [
        {
            field: {
                column: 'paid_at',
                table: 'payments',
                label: 'Fecha de Pago'
            },
            value: [null, null],
            control: {
                open: false,
                type: 'date',
            }
        }
    ];

    const [paymentsFiltersData, setPaymentsFiltersData] = useState(defaultPaymentsFiltersData);

    const movementsTableRef = useRef(null);
    const workerFeesTableRef = useRef(null);
    const paymentsTableRef = useRef(null);

    useEffect(() => {

        const init = async () => {
            await loadWorker();
        };

        init();
    }, []);

    const loadWorker = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/workers/${id}`);

            setWorker(response.data.worker);

        } catch(error){

            setError('Ocurrió un error cargando el trabajador.');

            // Mostrar mensaje de error
            enqueueSnackbar('Ocurrió un error cargando el trabajador.');

        }

        setIsLoading(false);
    };

    // Función que define los filtros y carga los registros
    const loadMovementsTableData = (query) => {

        return new Promise(async (resolve, reject) => {

            // Mostrar indicador de carga
            setIsMovementsLoading(true);

            query.filters.push({
                column: {
                    field: 'worker_id',
                },
                value: worker.id
            });

            // Generar el url
            const url = generateApiUrl(`${API_URL}/worker_movements`, query);

            try {

                // Realizar request
                const response = await axios.get(url);

                resolve({data: response.data.data, page: response.data.current_page - 1, totalCount: response.data.total});

            } catch(error){
                reject(new Error('Ocurrió un error cargando los registros.'))
            }

            // Esconder indicador de carga
            setIsMovementsLoading(false);

        }).catch((error) => {

            // Mostrar mensaje
            enqueueSnackbar('Ocurrió un error cargando los registros.');

        });
    };

    // Función que define los filtros y carga los registros
    const loadPaymentsTableData = (query) => {

        return new Promise(async (resolve, reject) => {

            // Mostrar indicador de carga
            setIsPaymentsLoading(true);

            query.filters.push({
                column: {
                    field: 'worker_id',
                },
                value: worker.id
            });

            query.filters.push({
                column: {
                    field: 'is_active'
                },
                value: 'checked'
            });

            // Generar el url
            const url = generateApiUrl(`${API_URL}/payments`, query, paymentsFiltersData);

            try {

                // Realizar request
                const response = await axios.get(url);

                resolve({data: response.data.data, page: response.data.current_page - 1, totalCount: response.data.total});

            } catch(error){
                reject(new Error('Ocurrió un error cargando los registros.'))
            }

            // Esconder indicador de carga
            setIsPaymentsLoading(false);

        }).catch((error) => {

            // Mostrar mensaje
            enqueueSnackbar('Ocurrió un error cargando los registros.');

        });
    };

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handleDelete = async () => {

        // Esconder el diálogo
        setIsDialogOpen(false);

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = selected.length;

            // Contadores para mensaje
            let deletedCount = 0;

            // Por cada categoría seleccionada
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${API_URL}/worker_movements/${selected[i].id}`);

                deletedCount += 1;
            }

            // Conseguir el mensaje
            const resultMessage = `${deletedCount} registros eliminados.`;

            // Mostrar mensaje
            enqueueSnackbar(resultMessage);

            // Vaciar selección
            setSelected([]);

            // Cargar tabla
            movementsTableRef.current.onQueryChange();

        } catch (error) {

            if(error.response.data.message)
            // Mostrar mensaje
                enqueueSnackbar(error.response.data.message);
            else
            // Mostrar mensaje
                enqueueSnackbar('Ocurrió un error eliminando los registros.');
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handlePaymentsDelete = async () => {

        // Esconder el diálogo
        setIsPaymentsDialogOpen(false);

        // Mostrar el indicador de carga
        setIsPaymentsLoading(true);

        try {

            // Total de seleccionados
            let count = paymentsSelected.length;

            // Contadores para mensaje
            let deletedCount = 0;

            // Por cada categoría seleccionada
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${API_URL}/payments/${paymentsSelected[i].id}`);

                deletedCount += 1;
            }

            // Conseguir el mensaje
            const resultMessage = `${deletedCount} registros cancelados.`;

            // Mostrar mensaje
            enqueueSnackbar(resultMessage);

            // Vaciar selección
            setPaymentsSelected([]);

            // Cargar tabla
            paymentsTableRef.current.onQueryChange();

        } catch (error) {

            if(error.response.data.message)
            // Mostrar mensaje
                enqueueSnackbar(error.response.data.message);
            else
            // Mostrar mensaje
                enqueueSnackbar('Ocurrió un error eliminando los registros.');
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handleDeactivate = async () => {

        // Esconder el diálogo
        setIsStatusDialogOpen(false);

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Cambiar estado
            await axios.delete(`${API_URL}/workers/${id}`);

            // Mostrar mensaje
            enqueueSnackbar('Estado actualizado exitosamente');

        } catch (error) {

            if(error.response.data.message)
            // Mostrar mensaje
                enqueueSnackbar(error.response.data.message);
            else
            // Mostrar mensaje
                enqueueSnackbar('Ocurrió un error activando o desactivando los registros.');
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const getBackURL = () => {
        const {referrer} = queryString.parse(location.search);

        switch (referrer) {
            case 'payments':
                return `/payments`;
            default:
                return '/workers';
        }
    };

    const renderEmergencyContacts = () => {
        return worker.emergency_contacts.map(emergencyContact => (
            <TableRow key={emergencyContact.id}>
                <TableCell component="th" scope="row">
                    {emergencyContact.name}
                </TableCell>
                <TableCell>{emergencyContact.phone}</TableCell>
                <TableCell>{emergencyContact.relationship}</TableCell>
            </TableRow>
        ));
    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isLoading ?
                            <div className={classes.progress}>
                                <CircularProgress/>
                            </div> :
                            error !== '' ?
                                <div className={classes.errorWrapper}>
                                    <WarningIcon color="primary" style={{marginRight: 8}}/>
                                    <Typography>{error}</Typography>
                                </div> :
                                <div className={[classes.paper]}>
                                    <Toolbar>
                                        <IconButton className={classes.backButton} component={NavLink} to={getBackURL()}>
                                            <ArrowBackIcon color="secondary"/>
                                        </IconButton>
                                        <Typography className={classes.title} variant="h6"
                                                    id="name">Trabajador</Typography>
                                    </Toolbar>
                                    <div style={{marginBottom: 30}}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'row'
                                        }}>
                                            <Avatar className={classes.avatar} src={`${APP_URL}/storage/workers/${worker.image}`}>{worker && worker.first_name[0]}</Avatar>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                marginLeft: 20
                                            }}>
                                                <Typography variant="h6"
                                                            style={{marginTop: 10}}>{worker && `${worker.first_name} ${worker.fathers_last_name} ${worker.mothers_last_name ? worker.mothers_last_name : ''}`}</Typography>
                                                <Typography variant="subtitle1">{worker && worker.curp}</Typography>
                                                <Typography variant="subtitle2">#{worker && worker.worker_number}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <Grid container spacing={3}>
                                        <Grid item md={9} xs={12}>
                                            <Paper className={classes.paper}>
                                                <div className={classes.padding}>
                                                    <Typography variant="h6" style={ { marginBottom: 10 } }>Información Personal</Typography>
                                                    <TableContainer>
                                                        <MaterialTable className={classes.table}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Fecha de Nacimiento</TableCell>
                                                                    <TableCell>Estado</TableCell>
                                                                    <TableCell>Sexo</TableCell>
                                                                    <TableCell>Estado Civil</TableCell>
                                                                    <TableCell>Último Estudio</TableCell>
                                                                    <TableCell>RFC</TableCell>
                                                                    <TableCell>IMSS</TableCell>
                                                                    <TableCell>Clave INE</TableCell>
                                                                    <TableCell>Folio INE</TableCell>
                                                                    <TableCell>Seccional</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">
                                                                        {moment(worker.birth_at).format('D MMM YYYY')}
                                                                    </TableCell>
                                                                    <TableCell>{worker.state.name}</TableCell>
                                                                    <TableCell>{worker.sex}</TableCell>
                                                                    <TableCell>{worker.civil_status}</TableCell>
                                                                    <TableCell>{worker.education_level}</TableCell>
                                                                    <TableCell>{worker.rfc}</TableCell>
                                                                    <TableCell>
                                                                        {worker.imss}
                                                                    </TableCell>
                                                                    <TableCell>{worker.ine_key}</TableCell>
                                                                    <TableCell>{worker.ine_folio}</TableCell>
                                                                    <TableCell>{worker.ine_section}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </MaterialTable>
                                                    </TableContainer>
                                                </div>
                                            </Paper>
                                            <div style={{marginBottom: 20}}>
                                                <Table
                                                    title="Movimientos de Empresa"
                                                    tableRef={movementsTableRef}
                                                    selected={selected}
                                                    onSelect={(rows) => setSelected(rows)}
                                                    loading={isMovementsLoading}
                                                    columns={movementsColumnObject}
                                                    loadTableData={(query) => loadMovementsTableData(query)}
                                                    onDestroy={() => setIsDialogOpen(true)}
                                                    destroyTooltip="Eliminar registro(s)"
                                                    onCreate={() => history.push('/worker_movements/create')}
                                                    onEdit={(id) => history.push(`/worker_movements/${id}/edit`)}
                                                    canCreate={authState.roleId !== 4}
                                                />
                                            </div>
                                            <div style={{marginBottom: 20}}>
                                                <Table
                                                    title="Pagos"
                                                    tableRef={paymentsTableRef}
                                                    selected={paymentsSelected}
                                                    onSelect={(rows) => setPaymentsSelected(rows)}
                                                    loading={isPaymentsLoading}
                                                    columns={paymentsColumnObject}
                                                    loadTableData={(query) => loadPaymentsTableData(query)}
                                                    onDestroy={() => setIsPaymentsDialogOpen(true)}
                                                    destroyTooltip="Eliminar registro(s)"
                                                    onCreate={() => history.push(`/workers/${id}/payments/create`)}
                                                    canCreate={authState.roleId !== 4}
                                                    canEdit={false}
                                                    detailPanel={rowData => <FeesDetailPanel rowData={rowData} />}
                                                    customActions={[
                                                        {
                                                            icon: FilterListIcon,
                                                            tooltip: 'Filtrar',
                                                            isFreeAction: true,
                                                            onClick: async (event) => {
                                                                setPaymentsFilterAnchor(event.currentTarget);
                                                            }
                                                        },
                                                    ]}
                                                />
                                                <FilterMenu
                                                    filtersConfig={defaultPaymentsFiltersData}
                                                    filterAnchor={paymentsFilterAnchor}
                                                    onClose={() => setPaymentsFilterAnchor(null)}
                                                    onSave={(data) => {
                                                        setPaymentsFiltersData(data);
                                                        paymentsTableRef.current.onChangePage(null, 0);
                                                        setPaymentsFilterAnchor(null);
                                                    }}
                                                    onClear={() => setPaymentsFiltersData(defaultPaymentsFiltersData)}
                                                />
                                            </div>
                                            <Paper className={classes.paper}>
                                                <div className={classes.padding}>
                                                    <Typography variant="h6" style={ { marginBottom: 10 } }>Contactos de Emergencia</Typography>
                                                    <TableContainer>
                                                        <MaterialTable className={classes.table}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Nombre</TableCell>
                                                                    <TableCell>Teléfono</TableCell>
                                                                    <TableCell>Parentesco</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                { renderEmergencyContacts() }
                                                            </TableBody>
                                                        </MaterialTable>
                                                    </TableContainer>
                                                </div>
                                            </Paper>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <div style={{marginBottom: 16}}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    aria-controls="actions-menu"
                                                    aria-haspopup="true"
                                                    onClick={(event) => setActionsAnchor(event.currentTarget)}>
                                                    Acciones
                                                </Button>
                                                <Menu
                                                    id="actions-menu"
                                                    anchorEl={actionsAnchor}
                                                    keepMounted
                                                    open={Boolean(actionsAnchor)}
                                                    onClose={() => setActionsAnchor(null)}
                                                >
                                                    <MenuItem
                                                        onClick={() => {
                                                            history.push(`/workers/${id}/edit`);
                                                            setActionsAnchor(null);
                                                        }}>
                                                        Editar
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            setIsStatusDialogOpen(true);
                                                            setActionsAnchor(null);
                                                        }}>
                                                        Activar/Desactivar
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={async () => {
                                                            worker.qr = await QRCode.toDataURL(worker.curp, {type: "png"});

                                                            const canvas = document.createElement("canvas");
                                                            JsBarcode(canvas, worker.curp, {displayValue: false});
                                                            worker.barcode = canvas.toDataURL("image/png");

                                                            setActionsAnchor(null);

                                                            setIsPrintDialogOpen(true);
                                                        }}>
                                                        Descargar Credencial
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={ async () => {
                                                            setActionsAnchor(null);

                                                            setIsLoading(true);

                                                            try {
                                                                const response = await axios.get(`${API_URL}/workers/${id}/data-report`,  {
                                                                    responseType: 'blob',
                                                                });

                                                                fileDownload(response.data, 'actualizacion_datos_sjoiim.pdf');
                                                            } catch (error) {
                                                                enqueueSnackbar('Ocurrió un error generando el documento');
                                                            }

                                                            setIsLoading(false);
                                                        }}>
                                                        Descargar Actualización de Datos
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={ async () => {
                                                            setActionsAnchor(null);

                                                            setIsLoading(true);
                                                            try{
                                                                const response = await axios.get(`${API_URL}/workers/${id}/privacy-report`,  {
                                                                    responseType: 'blob',
                                                                });

                                                                fileDownload(response.data, 'acuerdo_datos_sjoiim.pdf');
                                                            } catch (error) {
                                                                enqueueSnackbar('Ocurrió un error generando el documento');
                                                            }

                                                            setIsLoading(false);
                                                        }}>
                                                        Descargar Acuerdo de Datos
                                                    </MenuItem>
                                                </Menu>
                                            </div>
                                            <Paper className={classes.paper}>
                                                <List>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <EventAvailableIcon color="secondary"/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="Fecha de Alta" secondary={moment(worker.sign_up_at).format('D MMM YYYY')}/>
                                                    </ListItem>
                                                    {
                                                        worker.quit_at ?
                                                            <>
                                                            <Divider/>
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <EventBusyIcon color="secondary"/>
                                                                </ListItemIcon>
                                                                <ListItemText primary="Fecha de Baja" secondary={moment(worker.quit_at).format('D MMM YYYY')}/>
                                                            </ListItem>
                                                            </> :
                                                            null
                                                    }
                                                </List>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <List>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <LocationOnIcon color="secondary"/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="Dirección" secondary={`${worker.street_address} ${worker.suburb}`}/>
                                                    </ListItem>
                                                    {
                                                        worker.home_phone ?
                                                            <>
                                                                <Divider/>
                                                                <ListItem>
                                                                    <ListItemIcon>
                                                                        <PhoneIcon color="secondary"/>
                                                                    </ListItemIcon>
                                                                    <ListItemText primary="Teléfono"
                                                                                  secondary={worker.home_phone}/>
                                                                </ListItem>
                                                            </> :
                                                            null
                                                    }
                                                    {
                                                        worker.mobile_phone ?
                                                            <>
                                                                <Divider/>
                                                                <ListItem>
                                                                    <ListItemIcon>
                                                                        <SmartphoneIcon color="secondary"/>
                                                                    </ListItemIcon>
                                                                    <ListItemText primary="Teléfono Móvil"
                                                                                  secondary={worker.mobile_phone}/>
                                                                </ListItem>
                                                            </> :
                                                            null
                                                    }
                                                    {
                                                        worker.email ?
                                                            <>
                                                                <Divider/>
                                                                <ListItem>
                                                                    <ListItemIcon>
                                                                        <EmailIcon color="secondary"/>
                                                                    </ListItemIcon>
                                                                    <ListItemText primary="Correo Electrónico"
                                                                                  secondary={worker.email}/>
                                                                </ListItem>
                                                            </> :
                                                            null
                                                    }
                                                </List>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </div>
                    }
                </div>
            </Container>
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onAccept={ handleDelete }
                title="Eliminar registros"
            >
                <DialogContentText id="alert-dialog-description">
                    ¿Está seguro que desea eliminar los registros? Esta acción es permanente.
                </DialogContentText>
            </Dialog>
            {/*<Dialog*/}
            {/*    open={isFeeDialogOpen}*/}
            {/*    onClose={() => setIsFeeDialogOpen(false)}*/}
            {/*    onAccept={ handleFeeDelete }*/}
            {/*    title="Cancelar registros"*/}
            {/*>*/}
            {/*    <DialogContentText id="alert-dialog-description">*/}
            {/*        ¿Está seguro que desea cancelar los registros? Esta acción es permanente.*/}
            {/*    </DialogContentText>*/}
            {/*</Dialog>*/}
            <Dialog
                open={isPaymentsDialogOpen}
                onClose={() => setIsPaymentsDialogOpen(false)}
                onAccept={ handlePaymentsDelete }
                title="Eliminar registros"
            >
                <DialogContentText id="alert-dialog-description">
                    ¿Está seguro que desea eliminar los registros? Esta acción es permanente.
                </DialogContentText>
            </Dialog>
            <Dialog
                open={isStatusDialogOpen}
                onClose={() => setIsStatusDialogOpen(false)}
                onAccept={ handleDeactivate }
                title="Desactivar o activar registro"
            >
                <DialogContentText id="alert-dialog-description">
                    ¿Está seguro que desea desactivar o activar el registro?
                </DialogContentText>
            </Dialog>
            <MUIDialog
                open={isPrintDialogOpen}
                onClose={() => setIsPrintDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Imprimir Credencial"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Desea generar el documento para impresión de la credencial?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPrintDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <PDFDownloadLink style={ { textDecoration: 'none' } } document={ <Credentials data={[worker]} /> } fileName={`credencial_sjoiim.pdf`}>
                        {
                            ({ blob, url, loading, error }) => {
                                return <Button color="primary" autoFocus disabled={ loading }>
                                    DESCARGAR
                                </Button>
                            }
                        }
                    </PDFDownloadLink>
                </DialogActions>
            </MUIDialog>
        </>
    );
};

export default withSnackbar(WorkersView);
