import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import {withSnackbar} from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import WorkersCreateForm from "../../../components/Forms/WorkersCreateForm";
import axios from "axios";
import {API_URL} from "../../../config";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import WarningIcon from "@material-ui/core/SvgIcon/SvgIcon";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
}));

const WorkersCreate = ({ enqueueSnackbar, history }) => {

    const classes = useStyles();

    const authState = useSelector((state) => state.authReducer);

    const [states, setStates] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [fees, setFees] = useState([]);

    const sexes = [
        { label: 'Masculino', value: 'M'},
        { label: 'Femenino', value: 'F'}
    ];

    const civilStatuses = [
        { label: 'Sin Dato', value: 'Sin Dato'},
        { label: 'Soltero', value: 'Soltero'},
        { label: 'Casado', value: 'Casado'}
    ];

    const educations = [
        { label: 'Sin Dato', value: 'Sin Dato'},
        { label: 'Primaria', value: 'Primaria'},
        { label: 'Secundaria', value: 'Secundaria'},
        { label: 'Preparatoria', value: 'Preparatoria'},
        { label: 'Técnico', value: 'Técnico'},
        { label: 'Carrera Trunca', value: 'Carrera Trunca'},
        { label: 'Universidad', value: 'Universidad'},
    ];

    /* *** State de formulario *** */

    const [formFields, setFormFields] = useState({
        worker_number: '',
        curp: '',
        first_name: '',
        fathers_last_name: '',
        mothers_last_name: '',
        birth_at: '',
        state_id: '',
        sex: '',
        civil_status: '',
        education_level: '',
        education_observations: '',
        rfc: '',
        imss: '',
        ine_key: '',
        ine_folio: '',
        ine_section: '',
        home_phone: '',
        mobile_phone: '',
        email: '',
        street_address: '',
        suburb: '',
        sign_up_at: '',
        image: '',
        emergency_contacts: [],
        fees: [],
        signature: ''
    });

    /* *** State de formulario *** */

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [error, setError] = useState('');

    useEffect(() => {

        const init = async () => {
            await loadStates();
            await loadCompanies();
            await loadFees();
        };

        init();

    }, []);

    const loadStates = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/states`,
                {
                    params: {
                        sort: 'name',
                        direction: 'asc',
                        active: true
                    }
                }
            );

            // Actualizar state
            setStates(response.data);

        } catch(error) {

            setError('Ocurrió un error cargando los estados.');

            enqueueSnackbar('Ocurrió un error cargando los estados.');

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    const loadCompanies = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/companies`,
                {
                    params: {
                        sort: 'name',
                        direction: 'asc',
                        active: true
                    }
                }
            );

            // Actualizar state
            setCompanies(response.data);

        } catch(error) {

            setError('Ocurrió un error cargando las empresas.');

            enqueueSnackbar('Ocurrió un error cargando las empresas.');

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    const loadFees = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/fees`,
                {
                    params: {
                        sort: 'name',
                        direction: 'asc',
                        active: true,
                        is_recurrent: 1
                    }
                }
            );

            let feesData = response.data;

            // Actualizar state
            setFees(feesData);

            // Conseguir cuotas por defecto
            let defaultFees = feesData.filter(fee => fee.is_default).map(fee => ({ id: fee.id }));

            let newFormFields = {...formFields};

            newFormFields.fees = defaultFees;

            // Actualizar state
            setFormFields(newFormFields);

        } catch(error) {

            setError('Ocurrió un error cargando las cuotas.');

            enqueueSnackbar('Ocurrió un error cargando las cuotas.');

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isLoading ?
                            <div className={classes.progress}>
                                <CircularProgress/>
                            </div> :
                            error !== '' ?
                                <div className={classes.errorWrapper}>
                                    <WarningIcon color="primary" style={{marginRight: 8}}/>
                                    <Typography>{error}</Typography>
                                </div> :
                                <Paper className={classes.paper}>
                                    <LinearProgress style={{visibility: isSubmitting ? 'visible' : 'hidden'}}/>
                                    <Toolbar className={classes.padding}>
                                        <IconButton className={classes.backButton} component={NavLink} to="/workers">
                                            <ArrowBackIcon color="secondary"/>
                                        </IconButton>
                                        <Typography className={classes.title} variant="h6" id="title">Nuevo
                                            Trabajador</Typography>
                                    </Toolbar>
                                    <div>
                                        <div className={classes.padding}>
                                            <WorkersCreateForm
                                                initialValues={formFields}
                                                states={states}
                                                companies={companies}
                                                sexes={sexes}
                                                civilStatuses={civilStatuses}
                                                educations={educations}
                                                fees={fees}
                                                submitting={isSubmitting}
                                                history={history}
                                                onSuccess={(message) => enqueueSnackbar(message)}
                                                onError={(message) => enqueueSnackbar(message)}
                                                onLoadingChange={(loading) => setIsSubmitting(loading)}
                                            />
                                        </div>
                                    </div>
                                </Paper>
                    }
                </div>
            </Container>
        </>
    )
};

export default withSnackbar(WorkersCreate);
