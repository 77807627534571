import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import {API_URL} from "../../config";
import {withSnackbar} from "notistack";
import {throttle} from "throttle-debounce";
import {createFilterOptions} from "@material-ui/lab";
import {defaultStringify} from "../../helpers/helpers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    subtitle: {
        marginTop: theme.spacing(2)
    }
}));

const WorkersSearchField = ({ onWorkerSelect, enqueueSnackbar }) => {

    const classes = useStyles();

    const [workers, setWorkers] = useState([]);

    const [workersSearching, setWorkersSearching] = useState(false);

    const [open, setOpen] = useState(false);

    const filterOptions = createFilterOptions({
        ignoreAccents: true,
        ignoreCase: true,
        stringify: defaultStringify,
        trim: true
    });

    const handleWorkerInputChange = async (event, value, reason) => {

        if(reason === 'input' && value.length > 3){

            await loadWorkers(value);
        }

    };

    const workerSearchThrottled = throttle(1000, handleWorkerInputChange);

    const loadWorkers = async (term) => {

        setWorkersSearching(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/workers`,
                {
                    params: {
                        sort: 'curp',
                        direction: 'asc',
                        active: true,
                        search: term
                    }
                }
            );

            setWorkers(response.data);

        } catch(error) {

            enqueueSnackbar('Ocurrió un error cargando los trabajadores.');

        }

        setWorkersSearching(false);
    };

    return <Autocomplete
        open={open}
        onOpen={() => {
            setOpen(true);
        }}
        onClose={() => {
            setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => `${option.first_name} ${option.fathers_last_name} ${option.mothers_last_name ? option.mothers_last_name : ''} (${option.curp})`}
        options={workers}
        loading={workersSearching}
        filterOptions={filterOptions}
        noOptionsText="No hay resultados"
        loadingText="Cargando..."
        onInputChange={workerSearchThrottled}
        onChange={onWorkerSelect}
        renderInput={(params) => (
            <TextField
                {...params}
                label="Trabajador*"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {workersSearching ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        )}
    />
};

export default withSnackbar(WorkersSearchField);
