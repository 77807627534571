import React, {useState} from 'react';
import {Form} from "react-final-form";
import {Checkboxes, KeyboardDatePicker, Select, TextField} from "mui-rff";
import {makeStyles} from "@material-ui/core/styles";
import validate from "validate.js";
import Typography from "@material-ui/core/Typography";
import arrayMutators from 'final-form-arrays'
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import {isEmpty} from "lodash";
import {withStyles} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {COLOR_BACKGROUND, COLOR_PRIMARY} from "../../config/colors";
import moment from "moment";
import {FieldArray} from "react-final-form-arrays";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import {NavLink} from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import {API_URL} from "../../config";
import axios from 'axios';
import {pdf} from "@react-pdf/renderer";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    subtitle: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    backButton: {
        marginRight: 8
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    flexCenter: {
        display: 'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'row'
    },
    highlight: {
        fontWeight: 500
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    chip: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 8,
        paddingLeft: 8,
        borderRadius: 33,
        borderStyle: 'solid',
        borderWidth: 1,
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 700,
        display: 'inline'
    },
    chip_PRIMARY: {
        backgroundColor: COLOR_BACKGROUND,
        borderColor: COLOR_PRIMARY,
    },
    title: {
        marginRight: 10
    }
}));

validate.validators.array = (arrayItems, itemConstraints) => {

    const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
        item.index = index;
        const error = validate(item, itemConstraints, {fullMessages: false})
        if (error) errors.push({...error, index})
        return errors
    }, []);

    return isEmpty(arrayItemErrors) ? null : arrayItemErrors
};

validate.validators.customDate = function(value, options, key, attributes) {
    return moment.isMoment(value) && value.isValid() ? null : "Debe de ingresar una fecha válida";
};

const StyledTableRow = withStyles((theme) => ({
    root: {
        height: 70
    }
}))(TableRow);

const PaymentsCreateForm = ({ initialValues, fees, onSuccess, onError }) => {

    const classes = useStyles();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [payment, setPayment] = useState(null);

    const [isSaved, setIsSaved] = useState(false);

    // Función que se ejecuta al enviar formulario
    async function onSubmit(values, form) {

        // Mostrar indicador de carga
        setIsSubmitting(true);

        try {

            let data = {...values};

            data.payment_fees = data.payment_fees.filter(paymentFee => paymentFee.checked === true);
            data.total = getTotal(data.payment_fees);
            data.paid_at = data.paid_at.format('YYYY-MM-DD');

            delete data.payment;

            // Enviar datos a API
            const response = await axios.post(
                `${API_URL}/payments`,
                data
            );

            setPayment(response.data);

            // Mostrar mensaje
            onSuccess('Pago registrado.');

            // Marcar pago como guardado
            setIsSaved(true);

            // Esconder indicador de carga
            setIsSubmitting(false);

        } catch (error) {

            // Esconder indicador de carga
            setIsSubmitting(false);

            // Dependiendo del error, mostrar mensajes
            switch(error.response.status){
                case 400:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                case 422:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                default:
                    // Mostrar mensaje
                    onError("Ocurrió un error desconocido.");
                    break;
            }
        }

    }

    // Función que valida el formulario
    async function validateForm(values) {

        // Reglas de validación de formulario
        const constraints = {
            payment: {
                presence: {
                    allowEmpty: false,
                    message: 'Debe de llenar este campo'
                },
                numericality: {
                    greaterThanOrEqualTo: getTotal(values.payment_fees),
                    message: 'Debe ser un número mayor o igual al total'
                }
            },
            checkout_id: {
                presence: {
                    allowEmpty: false,
                    message: 'Debe de llenar este campo'
                }
            },
            paid_at: {
                presence: {
                    allowEmpty: false,
                    message: 'Debe de llenar este campo'
                },
                customDate: {
                    message: "Debe de ingresar una fecha válida"
                }
            },
            payment_fees: {
                array: {
                    id: {
                        presence: {
                            allowEmpty: false,
                            message: 'Debe de llenar este campo'
                        }
                    },
                    checked: {
                        presence: {
                            allowEmpty: false,
                            message: 'Debe de llenar este campo'
                        }
                    }
                }
            },
        };

        // Validar campos
        let valid = validate({...values, payment_fees_array: values.payment_fees}, constraints, {fullMessages: false});

        // Si no hay error
        if(!valid) {
            return;
        } else {
            return formatValidation(valid);
        }
    }

    const formatValidation = (valid) => {

        // Modificar objeto para mostrar errores en formulario
        Object.keys(valid).forEach((key, index) => {
            if(key === 'worker_fees'){
                let newValues = [];

                if(typeof valid[key][0] === 'string'){
                    valid[key] = valid[key][0];
                } else {
                    for(let i = 0; i < valid[key].length; i++){
                        newValues[valid[key][i].index] = formatValidation(valid[key][i]);
                    }

                    valid[key] = newValues;
                }

            } else if(key !== 'index'){
                valid[key] = valid[key][0];
            }
        });

        return valid;

    };

    // Función que manipula objeto para mostrar errores
    const handleServerError = (error) => {

        Object.keys(error).forEach((key, index) => {
            error[key] = error[key][0];
        });

        return error;
    };

    const getTotal = (paymentFees) => {
        return paymentFees.length > 0 ?
            paymentFees.filter(paymentFee => paymentFee.checked === true).reduce((sum, item) => sum + parseFloat(item.price), 0) :
            0
    };

    // const generatePdfDocument = async () => {
    //     const blob = await pdf((
    //         <SalePdf sale={sale} />
    //     )).toBlob();
    //
    //     saveAs(blob, `nota_${sale && sale.folio}.pdf`);
    // };

    const renderFees = (fields, push, values, form) => {
        return fields.map((name, index) => (
            <TableRow key={name}>
                <TableCell>
                    <Checkboxes
                        name={`${name}.checked`}
                        data={{label: '¿Pagar?', value: true}}
                        disabled={isSubmitting || isSaved}
                    />
                </TableCell>
                <TableCell component="th" scope="row">
                    {fees[index].name}
                </TableCell>
                <TableCell>${fees[index].price}</TableCell>
            </TableRow>
        ));
    };

    const generatePdfDocument = async () => {
        // const printBlob = await pdf((
        //     <Receipt payment={payment} />
        // )).toBlob();

        // if(sale.client.email){
        //
        //     const emailBlob = await pdf((
        //         <SalePdf sale={sale} size={[400, 800]}/>
        //     )).toBlob();
        //
        //     const base64 = await getBase64(emailBlob);
        //
        //     axios.post(`${API_URL}/sales/${sale.id}/mail`, {
        //         blob: base64
        //     });
        // }

        // saveAs(printBlob, `recibo_${payment && payment.id}.pdf`);
    };

    return (
        <Form
            onSubmit={onSubmit}
            mutators={{
                ...arrayMutators,
            }}
            initialValues={initialValues}
            validate={validateForm}
            render={({
                         handleSubmit,
                         values,
                         submitError,
                         form,
                         form: {
                             mutators: { push, pop }
                         }
                     }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item sm={8} xs={12}>
                            <Paper className={classes.paper}>
                                <div style={{paddingLeft: 16, paddingRight: 16}}>
                                    <Toolbar style={{paddingLeft: 0}}>
                                        <IconButton className={classes.backButton} component={NavLink} to={`/payments_search`}>
                                            <ArrowBackIcon color="secondary"/>
                                        </IconButton>
                                        <Typography className={classes.title} variant="h6" id="title">Nuevo Pago</Typography>
                                    </Toolbar>
                                    <div>
                                        <Table className={classes.table} size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell style={{width: '50%'}}>Cuota</TableCell>
                                                    <TableCell>Costo</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <FieldArray name="payment_fees">
                                                    {({ fields }) => renderFees(fields, push, values, form, isSubmitting)}
                                                </FieldArray>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Paper className={classes.paper}>
                                <LinearProgress style={{visibility: isSubmitting ? 'visible' : 'hidden'}}/>
                                <div style={{paddingLeft: 16, paddingRight: 16}}>
                                    <Table className={classes.table} size="small">
                                        <TableRow>
                                            <TableCell size="medium" className={classes.highlight}>Fecha</TableCell>
                                            <TableCell>
                                                <KeyboardDatePicker label="Fecha de Pago*" name="paid_at" disableFuture={true} format={'DD-MM-YYYY'} disableToolbar={false} disabled={isSubmitting || isSaved}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell size="medium" className={classes.highlight}>Caja</TableCell>
                                            <TableCell>
                                                <Select name="checkout_id" label="Caja" disabled={isSubmitting || isSaved}>
                                                    <MenuItem value="1">Caja 1</MenuItem>
                                                    <MenuItem value="2">Caja 2</MenuItem>
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell size="medium" className={classes.highlight}>Notas</TableCell>
                                            <TableCell>
                                                <TextField label="Notas" name="notes" size="small" multiline rows={2} style={{marginTop: 4}} disabled={isSubmitting || isSaved}/>
                                            </TableCell>
                                        </TableRow>
                                        <StyledTableRow>
                                            <TableCell size="medium" className={classes.highlight}>Total</TableCell>
                                            <TableCell size="medium">${ getTotal(values.payment_fees).toFixed(2) }</TableCell>
                                        </StyledTableRow>
                                        <TableRow>
                                            <TableCell size="medium" className={classes.highlight}>Pago</TableCell>
                                            <TableCell>
                                                <TextField label="Monto" name="payment" type="number" size="small" style={{marginTop: 4}} disabled={values.payment_fees.length === 0 || isSubmitting || isSaved}/>
                                            </TableCell>
                                        </TableRow>
                                        <StyledTableRow>
                                            <TableCell size="medium" className={classes.highlight}>Cambio</TableCell>
                                            <TableCell size="medium">{ values.payment ? '$' + (parseFloat(values.payment) - getTotal(values.payment_fees)).toFixed(2) : ''}</TableCell>
                                        </StyledTableRow>
                                    </Table>
                                    <div className={classes.flexCenter} style={{marginTop: 20}}>
                                        <Button
                                            color="secondary"
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            startIcon={<CreditCardIcon />}
                                            disabled={isSubmitting || isSaved || values.payment_fees.filter(fee => fee.checked === true).length === 0}
                                        >
                                            Registrar Pago
                                        </Button>
                                    </div>
                                    {/*<div className={classes.flexCenter} style={{marginTop: 10}}>*/}
                                    {/*    <Button*/}
                                    {/*        color="secondary"*/}
                                    {/*        type="button"*/}
                                    {/*        variant="contained"*/}
                                    {/*        fullWidth*/}
                                    {/*        startIcon={<ReceiptIcon />}*/}
                                    {/*        disabled={!isSaved}*/}
                                    {/*        onClick={() => generatePdfDocument()}*/}
                                    {/*    >*/}
                                    {/*        Generar Recibo*/}
                                    {/*    </Button>*/}
                                    {/*</div>*/}
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </form>
            )}
        />
    );
};

export default PaymentsCreateForm;
