import React, {useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as moment from 'moment';
import axios from 'axios';
import {API_URL} from "../../../config";
import Dialog from "../../../components/Dialog/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import Table from "../../../components/Table/Table";
import {generateApiUrl, generateFilterObject} from "../../../helpers/helpers";
import {withSnackbar} from "notistack";
import Container from "@material-ui/core/Container";
import {COLOR_SECONDARY} from "../../../config/colors";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import FilterListIcon from '@material-ui/icons/FilterList';
import SaveAlt from "@material-ui/icons/SaveAlt";
import fileDownload from "js-file-download";
import FilterMenu from "../../../components/FilterMenu/FilterMenu";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    id: {
        color: COLOR_SECONDARY,
        fontWeight: 'bold'
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

const WorkerMovementsIndex = ({ history, enqueueSnackbar }) => {

    const classes = useStyles();

    const authState = useSelector((state) => state.authReducer);

    const defaultFiltersData = [
        {
            field: {
                column: 'start_at',
                table: 'company_worker',
                label: 'Fecha de Inicio'
            },
            value: [null, null],
            control: {
                open: false,
                type: 'date',
            }
        },
        {
            field: {
                column: 'end_at',
                table: 'company_worker',
                label: 'Fecha de Fin'
            },
            value: [null, null],
            control: {
                open: false,
                type: 'date',
            }
        },
        {
            field: {
                column: 'created_at',
                table: 'company_worker',
                label: 'Fecha de Creación'
            },
            value: [null, null],
            control: {
                open: false,
                type: 'date',
            }
        }
    ];

    const [filterAnchor, setFilterAnchor] = useState(null);
    const [filtersData, setFiltersData] = useState(defaultFiltersData);

    // State de tabla
    const [selected, setSelected] = useState([]);

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);
    const [isInitLoading, setIsInitLoading] = useState(false);

    // State de diálogo
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    let [columnObject] = useState([
        {
            title: 'ID',
            field: 'id',
            filtering: false,
            defaultSort: 'desc',
            render: rowData => <Typography
                className={classes.id}>{rowData.id}</Typography>
        },
        { title: 'Empresa', field: 'name', width: 200 },
        {
            title: 'Trabajador',
            field: 'full_name',
        },
        { title: 'Puesto', field: 'job_position' },
        {
            title: 'Fecha de Inicio',
            field: 'start_at',
            filtering: false,
            render: rowData => {
                return moment(rowData.start_at).format('D MMM YYYY');
            }
        },
        {
            title: 'Fecha de Fin',
            field: 'end_at',
            filtering: false,
            render: rowData => {
                return rowData.end_at && moment(rowData.end_at).format('D MMM YYYY');
            }
        },
        {
            title: 'Creado',
            field: 'created_at',
            filtering: false,
            render: rowData => {
                return moment(rowData.created_at).format('D MMM YYYY');
            }
        }
    ]);

    const tableRef = useRef(null);

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handleDelete = async () => {

        // Esconder el diálogo
        setIsDialogOpen(false);

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = selected.length;

            // Contadores para mensaje
            let deletedCount = 0;

            // Por cada categoría seleccionada
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${API_URL}/worker_movements/${selected[i].id}`);

                deletedCount += 1;
            }

            // Conseguir el mensaje
            const resultMessage = `${deletedCount} registros eliminados.`;

            // Mostrar mensaje
            enqueueSnackbar(resultMessage);

            // Vaciar selección
            setSelected([]);

            // Cargar tabla
            tableRef.current.onQueryChange();

        } catch (error) {

            if(error.response.data.message)
            // Mostrar mensaje
                enqueueSnackbar(error.response.data.message);
            else
            // Mostrar mensaje
                enqueueSnackbar('Ocurrió un error eliminando los registros.');
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    // Función que define los filtros y carga los registros
    const loadTableData = (query) => {

        return new Promise(async (resolve, reject) => {

            // Mostrar indicador de carga
            setIsLoading(true);

            // Generar el url
            const url = generateApiUrl(`${API_URL}/worker_movements`, query, filtersData);

            try {

                // Realizar request
                const response = await axios.get(url);

                resolve({data: response.data.data, page: response.data.current_page - 1, totalCount: response.data.total});

            } catch(error){
                reject(new Error('Ocurrió un error cargando los registros.'))
            }

            // Esconder indicador de carga
            setIsLoading(false);

        }).catch((error) => {

            // Mostrar mensaje
            enqueueSnackbar('Ocurrió un error cargando los registros.');

        });
    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isInitLoading ?
                            <div className={classes.progress}>
                                <CircularProgress/>
                            </div> :
                            <Table
                                title="Movimientos de Trabajadores"
                                tableRef={tableRef}
                                loading={isLoading}
                                columns={columnObject}
                                loadTableData={(query) => loadTableData(query)}
                                selected={selected}
                                onSelect={(rows) => setSelected(rows)}
                                onCreate={() => history.push('/worker_movements/create')}
                                onEdit={(id) => history.push(`/worker_movements/${id}/edit`)}
                                onDestroy={() => setIsDialogOpen(true)}
                                canDelete={authState.roleId === 1}
                                destroyTooltip="Eliminar registro(s)"
                                shouldDefaultExport={false}
                                customActions={[
                                    {
                                        icon: FilterListIcon,
                                        tooltip: 'Filtrar',
                                        isFreeAction: true,
                                        onClick: async (event) => {
                                            setFilterAnchor(event.currentTarget);
                                        }
                                    },
                                    {
                                        icon: SaveAlt,
                                        tooltip: 'Exportar',
                                        isFreeAction: true,
                                        onClick: async (event) => {

                                            setIsLoading(true);

                                            try {

                                                console.log(tableRef.current.state.query);

                                                let data = generateFilterObject(tableRef.current.state.query, filtersData);

                                                const response = await axios.post(`${API_URL}/worker_movements/export`, data, {
                                                    responseType: 'blob',
                                                });

                                                fileDownload(response.data, 'movimientos_sjoiim.xlsx');

                                            } catch (error) {

                                                enqueueSnackbar('Ocurrió un error generando el reporte.');
                                            }

                                            setIsLoading(false);
                                        }
                                    }
                                ]}
                            />
                    }
                    <Dialog
                        open={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        onAccept={ handleDelete }
                        title="Eliminar Registros"
                    >
                        <DialogContentText id="alert-dialog-description">
                            ¿Está seguro que desea eliminar los registros? Esta acción es permanente.
                        </DialogContentText>
                    </Dialog>
                    <FilterMenu
                        filtersConfig={defaultFiltersData}
                        filterAnchor={filterAnchor}
                        onClose={() => setFilterAnchor(null)}
                        onSave={(data) => {
                            setFiltersData(data);
                            setFilterAnchor(null);
                            tableRef.current.onChangePage(null, 0);
                        }}
                        onClear={() => setFiltersData(defaultFiltersData)}
                    />
                </div>
            </Container>
        </>
    );

};

export default withSnackbar(WorkerMovementsIndex);
