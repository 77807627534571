import React from 'react';
import {Field, Form} from "react-final-form";
import {Checkboxes, TextField} from "mui-rff";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import validate from "validate.js";
import axios from "axios";
import {API_URL} from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import {toInputUppercase} from "../../helpers/helpers";
import createDecorator from "final-form-focus";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    subtitle: {
        marginTop: theme.spacing(2)
    }
}));

const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {
            ({ input: { value } }) => (value == is ? children : null)
        }
    </Field>
);

const FeesEditForm = ({ initialValues, onSuccess, onError, onLoadingChange, submitting }) => {

    const classes = useStyles();

    const focusOnErrors = createDecorator();

    // Reglas de validación de formulario
    const constraints = (values) => ({
        name: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        price: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            },
            numericality: {
                greaterThan: 0,
                message: 'Debe ser un número mayor a 0'
            }
        },
        months: (value, attributes, attributeName, options, constraints) => {
            if(values.is_recurrent) {
                return {
                    presence: {
                        allowEmpty: false,
                        message: 'Debe de llenar este campo'
                    },
                    numericality: {
                        onlyInteger: true,
                        greaterThan: 0,
                        message: 'Debe de ser un número entero mayor a 0'
                    }
                }
            } else {
                return null;
            }
        },
    });

    // Función que se ejecuta al enviar formulario
    async function onSubmit(values, form) {

        // Mostrar indicador de carga
        onLoadingChange(true);

        try {

            // Datos a enviar
            const data = {...values};

            if(!data.is_recurrent)
                data.months = null;

            // Enviar datos a API
            await axios.put(
                `${API_URL}/fees/${values.id}`,
                data
            );

            // Enviar mensaje de éxito a componente padre
            onSuccess('Cuota actualizada.');

            // Esconder indicador de carga
            onLoadingChange(false);

        } catch (error) {

            // Esconder indicador de carga
            onLoadingChange(false);

            // Dependiendo del error, mostrar mensajes
            switch(error.response.status){
                case 400:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                case 422:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                default:
                    // Mostrar mensaje
                    onError("Ocurrió un error desconocido.");
                    break;
            }

        }

    }

    // Función que valida el formulario
    async function validateForm(values) {

        // Validar campos
        let valid = validate({...values}, constraints(values), {fullMessages: false});

        // Si no hay error
        if(!valid)
            return;
        else {

            // Modificar objeto para mostrar errores en formulario
            Object.keys(valid).forEach((key, index) => {
                valid[key] = valid[key][0];
            });

            return valid;
        }
    }

    // Función que manipula objeto para mostrar errores
    const handleServerError = (error) => {

        Object.keys(error).forEach((key, index) => {
            error[key] = error[key][0];
        });

        return error;
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validateForm}
            decorators={[ focusOnErrors ]}
            render={({ handleSubmit, values, submitError, form }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Concepto*" name="name" inputProps={{onInput: toInputUppercase}} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Costo*" name="price"  />
                        </Grid>
                    </Grid>
                    <div className={ classes.buttonWrapper }>
                        <Button color="secondary" type="submit" disabled={submitting} startIcon={ submitting ? <CircularProgress size={18}/> : null }>Actualizar</Button>
                    </div>
                </form>
            )}
        />
    );
};

export default FeesEditForm;
