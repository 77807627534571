import React, {useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import axios from 'axios';
import {API_URL} from "../../../config";
import Dialog from "../../../components/Dialog/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import Table from "../../../components/Table/Table";
import {generateApiUrl, generateFilterObject, getDeactivatedMessage} from "../../../helpers/helpers";
import {withSnackbar} from "notistack";
import Container from "@material-ui/core/Container";
import {COLOR_SECONDARY} from "../../../config/colors";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {NavLink} from "react-router-dom";
import {SaveAlt} from "@material-ui/icons";
import fileDownload from "js-file-download";
import TableContainer from "@material-ui/core/TableContainer";
import UITable from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterMenu from "../../../components/FilterMenu/FilterMenu";
import {useSelector} from "react-redux";
import ImageIcon from '@material-ui/icons/Image';
import MUIDialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Credentials from "../../../components/Documents/Credentials";
import QRCode from 'qrcode';
import JsBarcode from "jsbarcode";
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    id: {
        color: COLOR_SECONDARY,
        fontWeight: 'bold'
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    filterMenuWrapper: {
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 10,
        backgroundColor: '#FAFAFA'
    },
    filterMenuHeader: {
        marginLeft: 10,
        marginRight: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    }
}));

const WorkersIndex = ({ history, enqueueSnackbar }) => {

    const classes = useStyles();

    const authState = useSelector((state) => state.authReducer);

    const sexes = [
        {
            label: 'Masculino',
            value: 'M'
        },
        {
            label: 'Femenino',
            value: 'F'
        }
    ];

    // State de tabla
    const [selected, setSelected] = useState([]);

    const [filterAnchor, setFilterAnchor] = useState(null);

    const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);
    const [isInitLoading, setIsInitLoading] = useState(false);

    // State de diálogo
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    let [columnObject] = useState([
        {
            title: 'ID',
            field: 'id',
            filtering: false,
            render: rowData => <NavLink to={`/workers/${rowData.id}`}><Typography
                className={classes.id}>{rowData.id}</Typography></NavLink>
        },
        { title: '# Trabajador', field: 'worker_number', width: 200 },
        { title: 'Nombre(s)', field: 'first_name' },
        { title: 'Apellido Paterno', field: 'fathers_last_name' },
        { title: 'Apellido Materno', field: 'mothers_last_name' },
        { title: 'CURP', field: 'curp' },
        {
            title: 'Estado',
            field: 'is_active',
            type: 'boolean',
            defaultFilter: 'checked'
        },
    ]);

    const defaultFiltersData = [
        {
            field: {
                column: 'name',
                table: 'companies',
                label: 'Empresa'
            },
            value: '',
            control: {
                open: false,
                type: 'text',
            }
        },
        {
            field: {
                column: 'sex',
                table: 'workers',
                label: 'Sexo'
            },
            value: '',
            control: {
                open: false,
                type: 'select',
                data: sexes
            }
        },
        {
            field: {
                column: 'sign_up_at',
                table: 'workers',
                label: 'Fecha de Alta'
            },
            value: [null, null],
            control: {
                open: false,
                type: 'date',
            }
        },
        {
            field: {
                column: 'created_at',
                table: 'workers',
                label: 'Fecha de Creación'
            },
            value: [null, null],
            control: {
                open: false,
                type: 'date',
            }
        }
    ];

    const [filtersData, setFiltersData] = useState(defaultFiltersData);

    const tableRef = useRef(null);

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handleDeactivate = async () => {

        // Esconder el diálogo
        setIsDialogOpen(false);

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = selected.length;

            // Contadores para mensaje
            let deactivatedCount = 0;
            let activatedCount = 0;

            // Por cada categoría seleccionada
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${API_URL}/workers/${selected[i].id}`);

                // Actualizar contador
                if(selected[i].is_active)
                    deactivatedCount += 1;
                else
                    activatedCount += 1;
            }

            // Conseguir el mensaje
            const resultMessage = getDeactivatedMessage(deactivatedCount, activatedCount);

            // Mostrar mensaje
            enqueueSnackbar(resultMessage);

            // Vaciar selección
            setSelected([]);

            // Cargar tabla
            tableRef.current.onQueryChange();

        } catch (error) {

            if(error.response.data.message)
            // Mostrar mensaje
                enqueueSnackbar(error.response.data.message);
            else
            // Mostrar mensaje
                enqueueSnackbar('Ocurrió un error activando o desactivando los registros.');
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    // Función que define los filtros y carga los registros
    const loadTableData = (query) => {

        return new Promise(async (resolve, reject) => {

            // Mostrar indicador de carga
            setIsLoading(true);

            // Generar el url
            const url = generateApiUrl(`${API_URL}/workers`, query, filtersData);

            try {

                // Realizar request
                const response = await axios.get(url);

                resolve({data: response.data.data, page: response.data.current_page - 1, totalCount: response.data.total});

            } catch(error){
                reject(new Error('Ocurrió un error cargando los registros.'))
            }

            // Esconder indicador de carga
            setIsLoading(false);

        }).catch((error) => {

            // Mostrar mensaje
            enqueueSnackbar('Ocurrió un error cargando los registros.');

        });
    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isInitLoading ?
                            <div className={classes.progress}>
                                <CircularProgress/>
                            </div> :
                            <Table
                                title="Trabajadores"
                                tableRef={tableRef}
                                loading={isLoading}
                                columns={columnObject}
                                loadTableData={(query) => loadTableData(query)}
                                selected={selected}
                                onSelect={(rows) => setSelected(rows)}
                                onCreate={() => history.push('/workers/create')}
                                onEdit={(id) => history.push(`/workers/${id}/edit`)}
                                onDestroy={() => setIsDialogOpen(true)}
                                canDelete={authState.roleId === 1}
                                canEdit={authState.roleId !== 4}
                                canCreate={authState.roleId !== 4}
                                customActions={[
                                    {
                                        icon: FilterListIcon,
                                        tooltip: 'Filtrar',
                                        isFreeAction: true,
                                        onClick: async (event) => {
                                            setFilterAnchor(event.currentTarget);
                                        }
                                    },
                                    {
                                        icon: SaveAlt,
                                        tooltip: 'Exportar',
                                        isFreeAction: selected.length === 0,
                                        onClick: async (event) => {

                                            setIsLoading(true);

                                            try {

                                                let data = generateFilterObject(tableRef.current.state.query, filtersData);

                                                if(selected.length > 0){
                                                    data.selected = selected.map(worker => worker.id);
                                                }

                                                const response = await axios.post(`${API_URL}/workers/export`, data, {
                                                    responseType: 'blob',
                                                });

                                                fileDownload(response.data, 'trabajadores_sjoiim.xlsx');

                                            } catch (error) {

                                                enqueueSnackbar('Ocurrió un error generando el reporte.');
                                            }

                                            setIsLoading(false);
                                        }
                                    },
                                    // {
                                    //     icon: ImageIcon,
                                    //     tooltip: 'Descargar Imágenes',
                                    //     isFreeAction: selected.length === 0,
                                    //     onClick: async (event) => {
                                    //
                                    //         setIsLoading(true);
                                    //
                                    //         try {
                                    //
                                    //             let data = generateFilterObject(tableRef.current.state.query, filtersData);
                                    //
                                    //             if(selected.length > 0){
                                    //                 data.selected = selected.map(worker => worker.id);
                                    //             }
                                    //
                                    //             const response = await axios.post(`${API_URL}/workers/download`, data, {
                                    //                 responseType: 'blob',
                                    //             });
                                    //
                                    //             fileDownload(response.data, 'trabajadores_sjoiim.zip');
                                    //
                                    //         } catch (error) {
                                    //
                                    //             enqueueSnackbar('Ocurrió un error descargando los archivos.');
                                    //         }
                                    //
                                    //         setIsLoading(false);
                                    //     }
                                    // },
                                    {
                                        icon: ContactPhoneIcon,
                                        tooltip: 'Generar Credenciales',
                                        isFreeAction: false,
                                        onClick: async (event) => {

                                            for(let i = 0; i < selected.length; i++){
                                                selected[i].qr = await QRCode.toDataURL(selected[i].curp, {type: "png"});

                                                const canvas = document.createElement("canvas");
                                                JsBarcode(canvas, selected[i].curp, {displayValue: false});
                                                selected[i].barcode = canvas.toDataURL("image/png");
                                            }

                                            setIsPrintDialogOpen(true);
                                        }
                                    }
                                ]}
                                shouldDefaultExport={false}
                                detailPanel={[
                                    rowData => ({
                                        disabled: !rowData.company,
                                        render: () => (
                                            <div style={{backgroundColor: '#FAFAFA'}}>
                                                <TableContainer>
                                                    <UITable className={classes.table}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Empresa</TableCell>
                                                                <TableCell>Puesto</TableCell>
                                                                <TableCell>Fecha Inicio</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">
                                                                    {rowData.company.name}
                                                                </TableCell>
                                                                <TableCell>{rowData.company.pivot.job_position}</TableCell>
                                                                <TableCell>{rowData.company.pivot.start_at}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </UITable>
                                                </TableContainer>
                                            </div>
                                        )
                                    })
                                ]}
                            />
                    }
                    <Dialog
                        open={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        onAccept={ handleDeactivate }
                        title="Desactivar o activar registros"
                    >
                        <DialogContentText id="alert-dialog-description">
                            ¿Está seguro que desea desactivar o activar los registros?
                        </DialogContentText>
                    </Dialog>
                    <MUIDialog
                        open={isPrintDialogOpen}
                        onClose={() => setIsPrintDialogOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">"Imprimir Credencial(es)"</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                ¿Desea generar el documento para impresión de las credenciales?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsPrintDialogOpen(false)} color="primary">
                                Cancelar
                            </Button>
                            <PDFDownloadLink style={ { textDecoration: 'none' } } document={ <Credentials data={selected} /> } fileName={`credenciales_sjoiim.pdf`}>
                                {
                                    ({ blob, url, loading, error }) => {
                                        return <Button color="primary" autoFocus disabled={ loading }>
                                            DESCARGAR
                                        </Button>
                                    }
                                }
                            </PDFDownloadLink>
                        </DialogActions>
                    </MUIDialog>
                    <FilterMenu
                        filtersConfig={defaultFiltersData}
                        filterAnchor={filterAnchor}
                        onClose={() => setFilterAnchor(null)}
                        onSave={(data) => {
                            setFiltersData(data);
                            tableRef.current.onChangePage(null, 0);
                            setFilterAnchor(null);
                        }}
                        onClear={() => setFiltersData(defaultFiltersData)}
                    />
                </div>
            </Container>
        </>
    );

};

export default withSnackbar(WorkersIndex);
